.board__container {
    width: 100%;
    @include x-padding();
    position: relative;
    .is-residential & {
        flex: 0 0 68%;
        max-width: 68%;
    }
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 3rem;
    padding-right: 0;
    @include media-breakpoint-up(lg) {
        padding-left: 2.175vw;
        padding-right: 0;
    }
    @include media-breakpoint-up(xl) {
        padding-left: 2.8vw;
        padding-right: 2vw;
    }
    @include media-breakpoint-up(xxl) {
        padding-left: 3vw;
        padding-right: 3vw;
    }

    .alert-container {
        .is-residential & {
            min-height: 6rem;
        }
        @include media-breakpoint-up(lg) {
            padding-right: 2.175vw;
        }
        // @include media-breakpoint-up(xxl) {
        //     padding-right: 2.175vw
        // }
    }
}

.alert-container-quote {
    margin-top: 4rem;
    margin-bottom: -3rem;
}

.board__container-scroll {
    @include custom-scrollbar();
    padding-bottom: 6rem;
    height: calc(100% - 30px);
    @include media-breakpoint-up(lg) {
        padding-right: 2.175vw;
    }
    @include media-breakpoint-up(xxl) {
        padding-right: 2.175vw
    }

    &.auto-placement {
        .switchboard__slot:hover {
            background-color: initial;
        }

        .hover-text {
            display: none;
        }
    }
}

.not-allowed {
    cursor: not-allowed !important;
}

.board-width {
    @include max-width();
    margin-left: auto;
    margin-right: auto;
}

.switchboard {
    @include max-width();
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2rem;

    overflow: hidden;
    @include media-breakpoint-up(xl) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @include media-breakpoint-up(xxl) {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .is-residential & {
        @include media-breakpoint-up(xl) {
            padding-left: 1.6rem;
            padding-right: 1.6rem;
        }
        @include media-breakpoint-up(xxl) {
            padding-left: 3.2rem;
            padding-right: 3.2rem;
        }
        @media (min-width: 1600px) {
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }
}

.switchboard__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 4rem;
}