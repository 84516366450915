@import 'register-success';
@import 'register-layout';
@import 'register-learn-more-cta';
@import 'register-landing';

.register {
    @include custom-scrollbar();
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 32px 2rem;
        border-bottom: 1px solid $grey-2;
        background-color: white;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            padding: 2rem 5.6rem;
            flex-direction: row;
            align-items: center;
        }
        &--step {
            display: block;
            @include media-breakpoint-up(md) {
                padding: 4vh 5.6rem;
            }
        }
        &-title {
            @include media-breakpoint-up(md) {
                margin-bottom: unset;
            }
        }
        .button + .register__header-title {
            margin-top: 1rem;
        }
        .steps {
            position: relative;
            bottom: -1rem;
            padding-bottom: 1rem;
            display: flex;
        }
    }
    &__body {
        padding: 32px 2rem;
        width: 100%;
        background-color: white;
        flex-grow: 1;
        @include media-breakpoint-up(md) {
            padding: 4vh 5.6rem;
        }
    }
    .form__actions {
        justify-content: flex-end;
        padding-top: 1.8rem;
        .button {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .row {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .col {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .login__cta {
        display: flex;
        align-items: center;
        @include media-breakpoint-up(lg) {
            margin-left: 16px
        }
        .button {
            width: 104px;
            margin-left: 1.4rem;
        }
    }
}
