// Technical Information
.guide {
    padding-top: 2rem;
    padding-bottom: 4rem;
    &__title {
        padding-bottom: 1.6rem;
        border-bottom: 1px solid #CBCBCB;
    }
    &__subtitle {
        display: block;
        margin-top: 1.5rem;
        color: black;
    }
    &__info {
        margin-top: 3rem;
        color: $grey-6;
        line-height: (25/16);
    }
    &__links {
        display: flex;
        padding-top: 1.2rem;
        margin-left: -1rem;
        margin-right: -1rem;
    }
    &__link-container {
        padding-left: 1rem;
        padding-right: 1rem;
        a {
            color: #3CC856;
          
        }
    }
}

.faqs {
    &.que-ans-container{
        margin-top: 4rem;
    }
    &__title {
        font-size: 3rem;
        padding-bottom: 1rem;
    }
    &__questions {
        font-size: 1.8rem;
        padding-bottom: 1rem;
        color: black;
        line-height: 2.1rem;
        letter-spacing: 0.01em;
    }
    &__answer {
        font-size: 1.6rem;
        color: #626469;
        line-height: 2.5rem;
        padding-bottom: 4rem;

        div > img{
            padding-bottom: 2rem;
        }
    }
    &__points {
        li {
            margin-left: 4rem;
        }
    }
    &__video-title {
        padding-top: 0 !important;
    }
}
