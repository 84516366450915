.switchboard__tabs {
    display: flex;
    @include media-breakpoint-up(xl) {
        margin-left: -4rem;
        margin-right: -4rem;
    }
}

.switchboard__tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 1.8rem;
    color: $grey-5;
    background-color: $grey-1;
    user-select: none;
    cursor: pointer;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 3px;
        right: 3px;
        height: 3px;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0);
    }
    &.is-active {
        color: $brand-1;
        background-color: white;
        .donut__amount {
            color: $body-colour;
        }
        .switchboard__tab-text {
            color: $brand-1;
        }
        &:before {
            background-color: $brand-1;
        }
    }
    &-donut {
        &.donut-container {
            padding-top: 0;
        } 
    }
    .donut {
        width: 8.6rem;
        height: 8.6rem;
        border-width: .8rem;
    }
    &-text {
        margin-bottom: 0;
        padding-left: 2rem;
        color: $grey-5;
    }
}