.loose-items {
    padding-top: 1rem;
}

.loose-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(98, 100, 105, .1);
    border-radius: .5rem;
    padding: 1.1rem 2rem;
    min-height: 6rem;
    background-color: rgba(203, 203, 203, .1);
    font-size: $f-sm;
    margin-bottom: 1rem;
    transition: all 3.5s ease;
    &.is-active {
        border: 1px solid $brand-1;
        background-color: $brand-1-extra-light;
        .loose-item__count,
        .button--add-remove.remove {
            opacity: 1;
            visibility: visible;
        }
    }
    &__text {
        padding-right: 2rem;
    }
    &__subtext {
        // padding-right: 2rem;
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0 0 8rem;
        max-width: 8rem;
    }
    &__count {
        color: $brand-1;
        transition: all .35s ease;
        opacity: 0;
        visibility: hidden;
    }
}

.button--add-remove {
    @include plus-minus-button();
    &.remove {
		opacity: 0;
		visibility: hidden;
	}
}
