

@if $custom-pages == true {
  @font-face {
    font-family: 'icomoon';
    src:  url('../icon-font/icomoon.eot?6g6elb');
    src:  url('../icon-font/icomoon.eot?6g6elb#iefix') format('embedded-opentype'),
          url('../icon-font/icomoon.woff2?6g6elb') format('woff2'),
          url('../icon-font/icomoon.ttf?6g6elb') format('truetype'),
          url('../icon-font/icomoon.woff?6g6elb') format('woff'),
          url('../icon-font/icomoon.svg?6g6elb#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
} @else {
  @font-face {
    font-family: 'icomoon';
    src:  url('../../../resources/icon-font/icomoon.eot?6g6elb');
    src:  url('../../../resources/icon-font/icomoon.eot?6g6elb#iefix') format('embedded-opentype'),
          url('../../../resources/icon-font/icomoon.woff2?6g6elb') format('woff2'),
          url('../../../resources/icon-font/icomoon.ttf?6g6elb') format('truetype'),
          url('../../../resources/icon-font/icomoon.woff?6g6elb') format('woff'),
          url('../../../resources/icon-font/icomoon.svg?6g6elb#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
}

@mixin icon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
  
[class^="i-"], [class*=" i-"] {
  @include icon();
}

.i-lightning-bolt:before {
  content: "\e92b";
}

.i-toolkit:before {
  content: "\e92a";
}
.i-commercial:before {
  content: "\e929";
}
.i-home:before {
  content: "\e927";
}
.i-add-board:before {
  content: "\e900";
}
.i-add-plus:before {
  content: "\e901";
}
.i-arrow-down:before {
  content: "\e902";
}
.i-arrow-left:before {
  content: "\e903";
}
.i-breaker:before {
  content: "\e904";
}
.i-check-circle:before {
  content: "\e905";
}
.i-check:before {
  content: "\e906";
}
.i-close:before {
  content: "\e907";
}
.i-consultant:before {
  content: "\e908";
}
.i-directional-right:before {
  content: "\e909";
}
.i-document:before {
  content: "\e90a";
}
.i-download:before {
  content: "\e90b";
}
.i-drag:before {
  content: "\e90c";
}
.i-edit:before {
  content: "\e90d";
}
.i-exclamation:before {
  content: "\e90e";
}
.i-external-Link:before {
  content: "\e90f";
}
.i-eye:before {
  content: "\e910";
}
.i-factory:before {
  content: "\e911";
}
.i-mail:before {
  content: "\e913";
}
.i-padlock:before {
  content: "\e914";
}
.i-plus:before {
  content: "\e915";
}
.i-project:before {
  content: "\e916";
}
.i-question:before {
  content: "\e917";
}
.i-refresh:before {
  content: "\e918";
}
.i-remove:before {
  content: "\e919";
}
.i-save:before {
  content: "\e91a";
}
.i-search:before {
  content: "\e91b";
}
.i-sort:before {
  content: "\e91c";
}
.i-sorting:before {
  content: "\e91d";
}
.i-switchboard-m:before {
  content: "\e91e";
}
.i-switchboard:before {
  content: "\e91f";
}
.i-tag:before {
  content: "\e920";
}
.i-contractor:before {
  content: "\e921";
}
.i-trash-bin:before {
  content: "\e922";
}
.i-calendar:before {
  content: "\e923";
}
.i-upload:before {
  content: "\e924";
}
.i-user-account:before {
  content: "\e925";
}
.i-wholesaler:before {
  content: "\e926";
}
.i-question:before {
  content: "\e928";
}
.i-duplicate:before {
  content: "\e950";
}

.i-favourite:before {
  content: "\e912";
}
.i-favourite-selected:before {
  content: "\e951";
}