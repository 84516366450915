
.l_project-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 2.8rem;
    padding-right: 2.4rem;
    border-left: 4px solid rgba(0, 0, 0, 0);

    .name {
        flex: 0 0 32%;
        max-width: 32%;
    }
    .job-number {
        flex: 0 0 16%;
        max-width: 16%;
    }
    .last-edited {
        flex: 0 0 16%;
        max-width: 16%;
        padding-right: 3rem;
    }
    .status {
        flex: 0 0 15%;
        max-width: 15%;
    }
    .actions {
        flex: 0 0 21%;
        max-width: 21%;
    }
}

.projects-header {
    padding-top: 1.2rem;
    padding-bottom: 2.2rem;
    margin-top: 2rem;
}

.projects-list {
    padding-bottom: 6rem;
}

.project {
    cursor: pointer;
    background-color: white;
    margin-bottom: 1rem;
    border-left: 4px solid $grey-5;
    height: 9rem;
    font-size: $f-sm;
    box-shadow: 0px 5px 20px rgba(98, 100, 105, 0.08);
    transition: all .35s ease;
    @media (hover) {
        &:hover {
            box-shadow: 0px 5px 20px rgba(98, 100, 105, 0.25);
            box-shadow: 0px 5px 20px rgba(88, 90, 95, 0.25);
        }
    }
    &.is-complete,
    &.is-available {
        border-left: 4px solid $brand-1;
    }
    &.is-in-progress {
        border-left: 4px solid $brand-2;
    }
    .status {
        .tag {
            width: 135px;
            text-align: center;
            justify-content: center;
            position: relative;
            left: -3rem;
        }
    }
    .actions {
        display: flex;
        justify-content: flex-end;
        .button {
            padding-right: 1rem;
            padding-left: 1rem;
            border: 0;
            background-color: rgba(0, 0, 0, 0);
            color: $grey-5;
            font-size: $f-xs;
            @media (hover) {
                &:hover {
                    color: black;
                }
            }
            .button__icon {
                width: 1.8rem;
                height: 1.8rem;
                font-size: 1.8rem;
                margin-right: .2rem;
            }
            & + .button {
                margin-left: 1rem;
            }
        }
    }
    .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 1.6rem;
    }
}

.sort-button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: $f-sm;
    user-select: none;
    &.is-active {
        // font-family: $f3;
        color: $brand-1-hover;
    }
    span {
        margin-left: 2rem;
    }
}