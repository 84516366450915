$slot-height: 11px;
// Switc1hboard Breaker
//--------------------------------
.switchboard__breaker-container {
    position: relative;
}

.switchboard__row {
    .switchboard__breaker {
        height: calc(100% + 1px);
        flex-direction: column;
        align-items: center;
        padding-top: 1.2rem;
        &:after {
            content: '';
            display: block;
            background-color: $grey-7;
            height: .5rem;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
        &.is-1-slot {
            .actions {
                flex-direction: column;
                justify-content: center;
                .button + .button {
                    margin-top: 1rem;
                }
            }
            .switchboard__breaker-check {
                display: flex;
            }
        }
        &.is-2-slot {
            width: calc(200% + 1px);
            .switchboard__breaker-check {
                display: flex;
            }
        }
        &.is-3-slot {
            width: calc(300% + 2px);
            .switchboard__breaker-check {
                display: flex;
            }
        }
        &.is-4-slot {
            width: calc(400% + 3px);
            .switchboard__breaker-check {
                display: flex;
            }
        }
        &.is-5-slot {
            width: calc(500% + 4px);
            .switchboard__breaker-check {
                display: flex;
            }
        }
        &.is-6-slot {
            width: calc(600% + 5px);
            .switchboard__breaker-check {
                display: flex;
            }
        }
        &.is-7-slot {
            width: calc(700% + 6px);
            .switchboard__breaker-check {
                display: flex;
            }
        }
        &.is-8-slot {
            width: calc(800% + 7px);
            .switchboard__breaker-check {
                display: flex;
            }
        }
        &-checklist {
            width: 100%;
            justify-content: center;
        }
        .input {
            padding-top: .3rem;
            padding-bottom: .3rem;
            padding-left: .4rem;
            padding-right: .4rem;
            @include media-breakpoint-down(xl) {
                font-size: $f-sm;
            }
            @include media-breakpoint-down(lg) {
                font-size: $f-xs;
            }
        }
        .breaker-check-container {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        &-check {
            font-size: $f-sm;
        }
        &-text {
            padding-top: 1.2rem;
            font-size: 1rem;
            color: rgba(255, 255, 255, .95);
            font-family: $f1;
            font-weight: 300;
            text-align: center;
            @include media-breakpoint-up(xxl) {
                display: flex;
                padding-top: 1.6rem;
                font-size: $f-xs;
            }
        }
        &-content {
            .input {
                width: calc(100% - 2rem);
                margin-left: auto;
                margin-right: auto;
                margin-top: 1rem;
            }
        }
        &-actions {
            width: 100%;
            height: 8rem;
            .actions {
                justify-content: space-evenly;
            }
        }
    }
}

.switchboard__grid {
    .switchboard__breaker {
        height: $grid-slot-height;
        align-items: center;
        &.is-3-slot,
        &.is-6-slot {
            // As per FE request this is moved up one section.
            margin-top: calc(#{$grid-slot-height} * -1);
        }
        &.is-2-slot {
            height: calc(#{$grid-slot-height} * 2);
        }
        &.is-3-slot {
            height: calc(#{$grid-slot-height} * 3);
        }
        &.is-4-slot {
            height: calc(#{$grid-slot-height} * 4);
        }
        &.is-6-slot {
            height: calc(#{$grid-slot-height} * 6);
        }
        &-checklist {
            width: 5rem;
            height: 100%;
            flex-direction: column;
        }
        &-check {
            margin-top: 2.2rem;
            margin-bottom: 2.2rem;
        }
        &-content {
            padding-right: 1.6rem;
        }
        &-actions {
            width: 9rem;
            height: 100%;
            @include media-breakpoint-up(xl) {
                width: 10rem;
            }
        }
    }
}

.switchboard__breaker {
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    border-top: 1px solid $grey-5;
    color: white;
    background: linear-gradient(99.26deg, #626469 1.52%, #53555A 98.74%);
    font-size: $f-sm;
    &:hover {
        .switchboard__breaker-actions {
            opacity: 1;
        }
    }

    &.is-1-slot {
        .switchboard__breaker-check {
            &:nth-child(1) {
                display: flex !important;
            }
        }
    }
    &.is-2-slot {
        .switchboard__breaker-check {
            &:nth-child(1),
            &:nth-child(2) {
                display: flex !important;
            }
        }
    }

    &.is-3-slot {
        .switchboard__breaker-check {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                display: flex;
            }
        }
    }
    &.is-6-slot {
        .switchboard__breaker-check {
            display: flex;
        }
    }
    &.is-5-slot {
        .switchboard__breaker-check {
            display: flex;
        }
    }

    &-checklist {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }

    &-check {
        @include display-center();
        flex-shrink: 0;
        display: none;
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 50%;
        color: white;
        background-color: rgba(203, 203, 203, .3);
        font-size: 1.2rem;
    }

    &-content {
        position: relative;
        flex-grow: 1;
    }

    &-note {
        padding: 2rem 0.4rem 0;
        margin-bottom: .4rem;
        color: white;
        font-size: 1.4rem;
        text-align: center;
    }

    &-text {
        display: block;
        padding: 0 0.4rem;
        color: $grey-4;
        font-size: $f-xs;
        // user-select: none; Temp commented as may need to copy & paste odd codes.
        @include media-breakpoint-up(xxl) {
            font-size: $f-sm;
        }
        .is-edit & {
            display: none;
        }
    }

    &-actions {
        position: relative;
        z-index: 100;
        background-color: $grey-6;
        transition: $breaker-action-transition;
        flex-shrink: 0;
        @media (hover) {
            opacity: 0;
        }
        .is-edit & {
            .actions--edit {
                opacity: 1;
                pointer-events: auto;
            }
        }
        .button {
            width: 2.6rem;
            height: 2.6rem;
            margin-left: .5rem;
            margin-right: .5rem;
            border: 1px solid white;
            color: white;
            background-color: rgba(0, 0, 0, 0);
            font-size: 1.2rem;
            &.delete {
                font-size: 1.4rem;
            }
            &.confirm {
                background-color: $brand-1-hover;
                border: 1px solid $brand-1-hover;
            }
        }
        .actions {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $grey-6;
            z-index: 100;
            &--edit {
                opacity: 0;
                pointer-events: none;
                .is-edit & {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

    .input {
        padding-top: 1.1rem;
        padding-bottom: 1.1rem;
        display: none;
    }
    &.is-edit {
        .input {
            display: block;
        }
        .switchboard__breaker-text { 
            position: absolute;
            top: 54px;
        }
    }

    // &.is-3-slot {
    //     height: calc(#{$slot-height} * 3);
    // }

    // &.is-6-slot {
    //     height: calc(#{$slot-height} * 6);
    // }

    .is-deleting,
    .is-disabled {
        pointer-events: none;
        opacity: 0.4;
    }
}
//--------------------------------