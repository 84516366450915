.tabs {
    &--sidebar {
        padding-top: 3rem;
    }

}
.tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    padding: .8rem 2rem;
    color: $grey-6;
    cursor: pointer;
    user-select: none;

    .tabs--sidebar & {
        padding: .8rem 1rem;
        margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
        .tab__text {
            margin-left: 0;
        }
        span + .tab__text {
            margin-left: .7rem;
        }
    }
    &.is-active {
        color: #009530;
        .tab__count {
            background-color: rgba(60, 200, 86, .2);
        }
        &:after {
            background-color: $brand-1;
        }    
    }
    &:after {
        position: absolute;
        left: 0;
        bottom: -2px;
        height: 2px;
        width: 100%;
        content: '';
        border-radius: 1px;
        background-color: rgba(0, 0, 0, 0);
    }

    &__text {
        margin-left: 1rem;
        font-size: $f-xs;
        @include media-breakpoint-up(xl) {
            font-size: $f-sm;
        }
    }

    &__count {
        @include nano-bold();
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: .2rem .4rem;
        min-width: 3.2rem;
        height: 2rem;
        border-radius: 1rem;
        background-color: $grey-2;
    }
    .i-favourite {
        margin-right: -2px;
    }
}