$slot-border-radius              : 3px;
$grid-slot-height                : 7rem;
$row-slot-height                 : 20rem;

@import "_layout";
@import "_breakers-board";
@import "_breakers-sidebar";
@import "_tabs";
@import "_board-header";
@import "_slot";
@import "_power-loss";

// For Residential layout
@import "switchboard-res-header";
