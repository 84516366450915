.register__cta {
    position: relative;
    z-index: 1;
    max-width: 420px;
    color: white;
    &-logo,
    &-text {
        margin-bottom: 3rem;
    }
    .button--outline {
        border-color: white;
        color: white;
    }
}

.register__se-logo {
    position: relative;
    margin-top: 16px;
    text-align: right;
}


.register__cta {
    &.in-carousel {
        width: 100%;
        max-width: 100%;
        @include media-breakpoint-up(md) {
            width: auto;
        }
    }
}

.app__register-side {
    &:has(.in-carousel) {
        flex-direction: row;
        align-items: end;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 6rem;
        @include media-breakpoint-up(md) {
            gap: 4rem;
            flex-wrap: nowrap;
            align-items: center;
                padding: 4rem;
            }
        &:before {
            background: rgba(0, 0, 0, 0.6);
        }
    }
}

.carousel-back { order:1; }
.carousel-next { order:2; }

.carousel-back,
.carousel-next {
    position: relative;
    flex-shrink: 0;
    display: flex;
    color: #3DCD58;
    background-color: transparent;
    border: 2px solid #3DCD58;
    padding: 14px;
    border-radius: 50%;
    cursor: pointer;
    @include media-breakpoint-up(md) {
        order: 0;
    }
}