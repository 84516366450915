.alert {
    padding: 1.6rem .5rem 1.5rem.5rem;
    border-radius: .5rem;
    margin-bottom: 1.6rem;
    text-align: center;
    font-size: 2rem;
    font-size: $f-sm;
    line-height: 1.2;
    &--error {
        color: $alert-error;
        background-color: $alert-error-bg;
    }
    &--warning {
        color: $alert-warning;
        background-color: $alert-warning-bg;
    }
    &--success {
        color: $alert-success;
        background-color: $alert-success-bg;
    }
    &__icon {
        display: inline-block;
        vertical-align: middle;
        padding-right: .6rem;
        font-size: 1.6rem;
    }
    .app__body--boards & {
        margin-top: 3rem;
        margin-bottom: -3rem;
    }
    .register__body-content & {
        margin-top: -2rem;
    }
}