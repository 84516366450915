body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;
}

.h1 {
    @include h1();
}

.h2 {
    @include h2();
}

.h3 {
    @include h3();
}

.h4 {
    @include h4();
}

.h5 {
    @include h5();
}

p,
.p {
	@include p();
}