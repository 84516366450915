// Grid overlay For development only
$showGrid                           : true;
$bodyRuler                          : tru;
$grid-bg                            : rgba(255, 0, 0, .08);

$easing-swing                       : cubic-bezier(0.75, 0, 0.25, 1);

/////////////////////////////

// Amimation
$animation-function                 : cubic-bezier(.75,0,.25,1);

// Site Header 
$site-header-height-sm              : 60px;
$site-header-height-md              : 80px;
$site-header-height-lg              : 90px;
$site-header-bg                     : orange;

// Site Footer 
$site-footer-bg                     : #081318;
$site-footer-secondary-bg           : black;
$site-footer-colour                 : white;

// Z-index

$flyout-index                       : 5;
$modal-index                        : 10;
$overlay-index                      : 15;

$arrow-dark                         : url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M2.02383 4.05454L5.10015 1.04169L5.10026 1.0418L5.10454 1.03716L5.13231 1.00707L5.13243 1.00718L5.1365 1.0023C5.30691 0.797761 5.29815 0.493529 5.1079 0.299267C4.90502 0.0921119 4.57263 0.0886416 4.36548 0.291515L0.89881 3.68654L0.898706 3.68644L0.894564 3.69091L0.867642 3.71998L0.867532 3.71987L0.863589 3.72457C0.686995 3.93505 0.701734 4.25085 0.90581 4.44345L4.37248 7.71509L4.37237 7.7152L4.37717 7.7193L4.40833 7.74587L4.40823 7.74599L4.41326 7.74986C4.62435 7.91209 4.928 7.89135 5.11463 7.69361C5.31363 7.48274 5.30402 7.15047 5.09315 6.95146C5.09315 6.95146 5.09315 6.95146 5.09315 6.95145L2.02383 4.05454Z' fill='%23626469' stroke='%23626469' stroke-width='0.25'/%3e%3c/svg%3e ");

$arrow-light                        : url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M2.02383 4.05454L5.10015 1.04169L5.10026 1.0418L5.10454 1.03716L5.13231 1.00707L5.13243 1.00718L5.1365 1.0023C5.30691 0.797761 5.29815 0.493529 5.1079 0.299267C4.90502 0.0921119 4.57263 0.0886416 4.36548 0.291515L0.89881 3.68654L0.898706 3.68644L0.894564 3.69091L0.867642 3.71998L0.867532 3.71987L0.863589 3.72457C0.686995 3.93505 0.701734 4.25085 0.90581 4.44345L4.37248 7.71509L4.37237 7.7152L4.37717 7.7193L4.40833 7.74587L4.40823 7.74599L4.41326 7.74986C4.62435 7.91209 4.928 7.89135 5.11463 7.69361C5.31363 7.48274 5.30402 7.15047 5.09315 6.95146C5.09315 6.95146 5.09315 6.95146 5.09315 6.95145L2.02383 4.05454Z' fill='%239FA0A4' stroke='%239FA0A4' stroke-width='0.25'/%3e%3c/svg%3e ");

//     /\ is old but might still need \/ is new

// SIZES
$root-font-size 			        : 10px;
$base-font-size 			        : 1.6rem;

// Colours 
$grey-1                             : #FAFAFA;
$grey-2                             : #F3F2F2;
$grey-3                             : #E7E6E6;
$grey-4                             : #CBCBCB;
$grey-5                             : #9FA0A4;
$grey-6                             : #626469;
$grey-7                             : #333333;

$brand-1                            : #3CC856;
$brand-1-hover                      : #1EAE43;
$brand-1-light                      : rgba(60, 200, 86, .3);
$brand-1-extra-light                : rgba(60, 200, 86, .1);
$brand-2                            : #42B4E6;
$brand-2-hover                      : #0087CD;
$brand-3                            : $grey-2;
$brand-3-hover                      : $grey-3;

$red                                : #E74C3C; // Not sure what else to call it?

$tag-1                              : rgba(60, 200, 86, .05);
$tag-2                              : rgba(33, 155, 253, .05);

$warning-colour                       : #F39C12;
$error-colour                       : #EE3430;
$error-colour-mute                  : rgba(231, 76, 60, .1);
$error-colour-mute-text             : rgba(231, 76, 60, .5);

$alert-error                        : $red;
$alert-error-bg                     : rgba(231, 76, 60, .05);
$alert-warning                      : #F39C12;
$alert-warning-bg                   : #F9F2D7;
$alert-success                      : #42B4E6;
$alert-success-bg                   : rgba(52, 152, 219, .05);

// Forms
$input-border                       : #EAEBEB;
$input-border-dark                  : rgba(243, 244, 245, .5);
$form-light-placeholder             : rgba(7, 4, 4, 0.5);
$form-placeholder                   : $grey-5;

// Global
$body-colour                        : $grey-6;
$body-bg                            : white;

// Responsive site sizing
$x-sm                               : 1.5vw;
$x-md                               : 1.75vw;
$x-lg                               : 2vw;
$x-xl                               : 3.5vw;
$x-xxl                              : 5vw;

// Animations Transitions
$input-focus                        : all .35s ease;

$cubic-slow-fast                    : cubic-bezier(.66,.12,.11,.77);
$cubic-fast-slow                    : cubic-bezier(.43,.22,.22,.88);

$slot-transition                    : background .56s ease;
$slot-text-transition               : opacity .56s ease;

$breaker-action-transition          : opacity .35s ease;

// Switch on board sections
$switch-text-transition             : .35s $cubic-slow-fast;
$switch-slider-transition           : .35s $cubic-fast-slow;

// Modal and overlay
$overlay-bg                         : rgba(0, 0, 0, .75);

// Quote
$quote-table-border                 : $grey-3;