.demo-page-list {
	padding-bottom: 6rem;
	list-style-type: none;
	li {
		margin-bottom: 1rem;
	}
	.primary-link {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $brand-1;
		text-decoration: none;
		padding-top: .4rem;
		padding-bottom: .4rem;
		padding: 1.6rem 1.8rem 1.6rem 2.4rem;
		border: 1px solid $brand-1;
		display: flex;
		height: 64px;
	}
}

.demo {
	.button--cta {
		position: relative;
		top: 0;
		left: 0;
	}
	&-title {
		padding: .8rem;
		background-color: rgb(202, 52, 127);
		color: lime;
		margin-top: 4rem;
		margin-bottom: 2rem;
	}
}

.demo__actions {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	.action {
		position: relative;
		width: 4.4rem;
		height: 100%;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 3rem;
		&:last-child {
			width: 5.8rem;
			justify-content: flex-end;
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: -1.7rem;
				bottom: -1.7rem;
				left: 10px;
				width: 1px;
				background-color: black;
			}
		}
	}
}

.demo-icon {
	padding-top: 2rem;
	display: inline-block;
	width: 200px;
	text-align: center;
	&__text {
		margin-top: .8rem;
	}
}
