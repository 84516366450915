// [class^="i-"], [class*=" i-"] {
//     display: inline-block;
//     flex-shrink: 0;
//     width: 16px;
//     height: 16px;
//     vertical-align: middle;
// 	fill: currentColor !important;
// 	path {
// 		fill: currentColor !important;
// 	}
// }

.u_text-hide {
	@include hide-text();
}

.u_text-gap-right {
	padding-right: 1rem;
}

.u_image-cover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-position: center center;
}

.u_text-light {
	color: white;
}

.u_mute-text {
    color: $grey-5;
}

.u_text-brand {
	color: $brand-1;
}

.u_no-wrap {
    white-space: nowrap;
}

.u_justify-center {
	justify-content: center;
}

[hidden] {
	display: none !important;
}