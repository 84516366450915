// Card used on managing boards.
.card-container {
    max-width: 386px;
    min-width: 320px;
    width: 33.333333%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(xl) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @include media-breakpoint-up(xxl) {
        width: 25%;
    }
}
.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background-color: white;
    box-shadow: 0px 10px 20px rgba(98, 100, 105, 0.08);
    &:hover {
        .card__actions {
            .button {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    &__quantity {
        padding-bottom: 1.4rem;
        margin-top: -1rem;
        margin-left: auto;
        margin-right: auto;
    }
    
    &__title {
        flex-grow: 1;
        color: $grey-7;
    }
    &__actions {
        .button {
            width: 100%;
            & + .button {
                margin-top: 1rem;
            }
        }
        &--hover {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: .5rem;
            .button {
                transition: $input-focus;
            }
            .button__icon {
                width: 2rem;
                height: 2rem;
                font-size: 2rem;
            }
            .button + .button {
                margin-top: 0;
            }
        }
    }

    &__chassis-container {
        display: flex;
        justify-content: center;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1.6rem;
    }
    &__chassis-title {
        padding-top: .6rem;
        margin-bottom: 0;
        line-height: 1;
    }
    &__chassis {
        flex-grow: 1;
        & + .card__chassis {
            border-left: 1px solid $grey-3;
        }
    }
    .donut-container {
        padding-top: 1rem;
        color: $grey-4;
    }
    .donut {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        // border: 10px solid $grey-2;
    }
    .donut__amount {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $grey-5;
    }
    &.is-error {
        .card__title {
            color: $error-colour;
        }
        .donut-container {
            color: $error-colour-mute-text;
        }
        .donut__amount {
            color: $error-colour;
        }
    }
    .disabled-quantity {
        background: gray;
        opacity: 0.5;
        cursor: wait;
    }
}

.board-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6rem;
    margin-left: -1rem;
    margin-right: -1rem;
    @include media-breakpoint-up(xl) {
        margin-left: -2rem;
        margin-right: -2rem;
    }
}

.board-message-mute {
    color: $grey-5;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    p,
    svg {
        margin-bottom: 2.4rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}



.donut-container {
    padding-top: 1rem;
    color: $grey-4;
}
.donut {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    // border: 10px solid $grey-2;
}
.donut__amount {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey-5;
}

.board-landing-page {
    @include media-breakpoint-up(xl) {
        margin: 0 4.5vw;
    }
    @include media-breakpoint-up(lg) {
        margin: 0 5vw;
    }
    @include media-breakpoint-up(xxl) {
        margin: 0 5vw;
    }
    position: relative;
    padding: 4.8rem 5.6rem 3.7rem 4.8rem;
    border: 1px solid #E7E6E6;
    background: #FFFFFF;
    margin-top:5rem !important;
    &__landing-image{
        height: 37rem;
        width: 37rem;
        margin-left: 3.6rem;
        background-image: url('../../img/landing-page.png');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
    &__close-icon{
        position: absolute;
        top: 0;
        right: 0;
        margin: 2.9rem;
        cursor: pointer;
    }
    &__how-it-works {
        font-size: 2.2rem;
        color: #009530;
        line-height: 2.5rem;
        text-decoration-line: underline;
        margin-top: 3.6rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        > div {
            display: flex;
            margin-left: 0.8rem;
        }
    }
    &__title {
        font-size: 3rem;
        line-height: 3.4rem;
        color: #626469;
        margin-bottom: 2.8rem;
    }
    &__new-project {
        cursor: pointer;
    }
    &__cards {
        display: flex;
        gap: 3.6rem;
        padding:2.4rem;
        background: #FAFAFA;
        border: 1px solid #E7E6E6;
        margin-bottom: 2.4rem;
        
        .i-document, 
        .i-switchboard, 
        .i-check {
            font-size: 3rem;
            color: #333333;
        }
        
        &__title {
            font-size: 2.2rem;
            line-height: 2.5rem;
            color: #333333;
            padding-bottom: 1.6rem;
        }
        &__sub-title {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.5rem;
            color: #333333;
        }
    }
}