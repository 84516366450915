.header {
    @include x-padding();
    &__logo {
        display: block;
        img {
            width: 115px;
        }
    }

    &__section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem 0;
        .tag {
            position: relative;
            margin-left: 4rem;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 2rem;
                left: -2rem;
                border-left: 1px solid $grey-3;
            }
        }
    }
    &__section-col {
        display: flex;
        align-items: center;
    }
    &__title {
        margin-bottom: 0;
        & + .button {
            margin-left: 2rem;
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin-left: 1.2rem;
        flex-shrink: 0;
        .button + .button {
            margin-left: 1rem;
            @include media-breakpoint-up(lg) {
                margin-left: 2rem;
            }
            @include media-breakpoint-down(xl) {
                margin-left: 1rem;
            }
        }
        .button-add,
        .button-close {
            @include media-breakpoint-down(lg) {
                .button__text-lg {
                    display: none;
                }
            }
        }
    }
    &__dropdown {
        position: relative;
        &:hover,
        &.is-open {
            .header__dropdown-list {
                opacity: 1;
                pointer-events: auto;
            }
        }
        .button {
            margin-right: 1.6rem;
            margin-left: 2.4rem;
            .button__text {
                margin-right: .8rem;
            }
        }
        &-list {
            position: absolute;
            top: calc(100% - 4px);
            width: 167px;
            left: 2.5rem;
            padding-left: 0;
            padding-top: .8rem;
            padding-bottom: .8rem;
            list-style-type: none;
            box-shadow: 0px 5px 20px rgba(98, 100, 105, 0.08);
            border-radius: 5px;
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            background-color: white;
            a {
                display: block;
                padding: .8rem 2rem;
                text-decoration: none;
                color: $grey-6;
                font-size: $f-sm;
            }
        }
    }
}

.header__section {
    &.hs_minimal {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
        @media (min-height: 780px) {
            padding-top: 3.2rem;
            padding-bottom: 3.2rem;
        }
    }
    &.hs_title {
        padding-top: 0;
        padding-bottom: 6.4rem;
        margin-top: -.8rem;
    }
    &.hs_breadcrumbs {
        padding-top: 0;
        margin-top: -1.8rem;
        padding-bottom: 0;
    }
    &.hs_board {
        padding-top: 1rem;
    }
    &.hs_tabs {
        cursor: pointer;
        padding-top: 0;
        padding-bottom: 0;
    }
    &.hs_switchboard {
        @include media-breakpoint-down(xl) {
            .button-edit {
                .button__text {
                    display: none;
                }
            }
            .button {
                padding-left: 1.2rem;
                padding-right: 1.2rem;
                flex-shrink: 0;
                margin-left: 1rem;
            }
            .button-edit {
                width: 48px;
                flex: 0 0 48px;
                max-width: 48px;
                min-width: 48px;
                height: 48px;
                padding: 0 !important;
                display: inline-flex;
            }
        }
    }
    &.hs_project-start {
        @media (min-height: 780px) {
            padding-top: 4.4rem;
        }
        .header__actions {
            display: flex;
            align-items: center;
            .button--1 {
                margin-left: 2.8rem;
            }
        }
        .header__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 580px;
            @include media-breakpoint-up(xl) {
                width: 640px;
            }
            @include media-breakpoint-up(xxl) {
                width: 676px;
            }
        }
    }
}


.header__bar {
    display: flex;
    align-items: center;
    .header-logo-container {
        padding-right: 1.6rem;
    }
    .header__title {
        position: relative;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:after {
            content: '';
            border-right: 1px solid $grey-4;
            height: 100%;
            position: relative;
            right: -1.2rem;
            flex-shrink: 0;
            // display: block;
        }
        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }
}


.header-logo-container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-top: 8px;
    padding-bottom: 4px;
    .divider {
        content: '';
        width: 1px;
        border-left: 1px solid $grey-6;
        opacity: .25;
        height: 22px;
        margin-top: -4px;
        display: block;
        margin-left: 1.6rem;
        margin-right: 1.6rem;
    }
}
// Fix for jumping page changes
.header__brand {
    margin-top: -2px;
    svg {
        display: block;
    }
}

.header__search {
    position: relative;
    &-button {
        position: absolute;
        top: 50%;
        right: 4px;
        border: 0;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0);
        cursor: auto;
    }
    &-input {
        border-radius: 2.4rem;
        border:1px solid $grey-3;
        padding-left: 1.6rem;
    }
}