.quote-table__row {
    display: flex;
    min-height: 6rem;

    &:last-child {
        font-size: 1.8rem;
        font-family: $f2;
        font-weight: normal;
        color: black;
        .markup {
            font-weight: normal;
            font-family: $f3;
            font-weight: 700;
        }
        > div {
            padding-top: 2rem;
            justify-content: flex-start;
        }
        .email-customer-quote {
            position: absolute;
            bottom: -3rem;
            font-size: 1.4rem;
            font-weight: normal;
            font-family: $f2;
            @media (max-width: 1200px) {
                padding-left: 1.2rem;
                padding-right: 1.2rem;
            }
        }
    }
    > div {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-grow: 1;
        border-bottom: 1px solid $quote-table-border;
        .u_mute-text {
            font-size: $f-xs;
        }
    }
    .boards {
        flex: 0 0 27%;
        max-width: 27%;
        padding-left: 2.4rem;
        flex-wrap: wrap;
        padding-right: .8rem;
        color: $grey-7;
    }
    .price {
        flex: 0 0 16.5%;
        max-width: 16.5%;
    }
    .gst {
        flex: 0 0 16.5%;
        max-width: 16.5%;
    }
    .total {
        flex: 0 0 18%;
        max-width: 18%;
        padding-right: 1rem;
        border-left: 1px solid $grey-3;
        padding-left: 2rem;
    }
    .markup {
        flex: 0 0 22%;
        max-width: 22%;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        padding-bottom: 1.1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-bottom: 1px solid transparent;
        position: relative;
        @media(min-width: 1440px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        @media(min-width: 1600px) {
            padding-left: 2.8rem;
            padding-right: 2.8rem;
        }
        &:after {
            display: block;
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            border-top: 1px solid $quote-table-border;
            left: -1rem;
            right: -1rem;

        }
        .small {
            font-size: 1rem;
            display: block;
            color: $grey-6;
        }
        position: relative;
        z-index: 1;
    }
    .old-price {
        font-size: 1rem;
        display: block;
        color: $grey-6;
    }
}

.quote-table__row--removeSellPrice {
    .boards {
        flex: 0 0 40%;
        max-width: 40%;
    }

    .price {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .gst {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .total {
        flex: 0 0 20%;
        max-width: 20%;
        border-right: 1px solid #E7E6E6;
    }
}

.quote-table__header {
    font-size: $f-sm;
    min-height: 4.8rem;
    > div {
        color: $grey-7;
    }
    .markup {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        &:after {
            display: none;
        }
    }
    &--wholesaler-n-manufacturer {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

.quote__header--removeSellPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >h3 {
        margin-bottom: 0;
    }
}

.markup-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90px;
    margin-left: 2rem;
    font-size: $f-xs;
    
    .decrease {
        &.is-disabled {
            background-color: initial;
            border: none;
            color: white;

            .button__icon {
                background-color: $grey-5;
            }
        }
    }

    &__amount {
        position: relative;
        margin-left: 2px;
        margin-right: 2px;
        height: 36px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &-input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            pointer-events: none;
        }
        &-text {
            color: $grey-6;
        }
        .input {
            font-size: $f-xs;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 2px;
            padding-right: 2px;
            text-align: center;
        }
        &.is-active {
            .markup-control__amount-input {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}

.button--markup {
    @include plus-minus-button();
}

.quote-table {
    position: relative;
    margin-top: 3rem;
    border-top: 1px solid $quote-table-border;
    border-left: 1px solid $quote-table-border;
    border-radius: 5px 0 0 0;
    .button--outline {
        padding: .6rem .8rem;
        font-size: $f-xs;
        margin-top: .6rem;
        margin-bottom: .6rem;
    }
    &:after {
        content: '';
        display: block;
        background-color: #F9F9F9;
        position: absolute;
        right: -1rem;
        top: -.8rem;
        bottom: -1rem;
        width: calc(22% + 1.6rem);
        border-radius: 3px;
        border: 1px solid rgba(231, 230, 230, .5);
    }
    .button--clear {
        color: $brand-1;
        padding-left: 0;
        .button__icon {
            font-size: 1.6rem;
        }
    }
    &--1 {
        margin-bottom: 4rem;
        &:after {
            bottom: -6.4rem;
        }
    }
    &--2 {
        .boards {
            flex: 0 0 78%;
            max-width: 78%;
        }
    }
    &--consultant {
        .boards {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .pc {
        position: relative;
        width: 100%;
        &__button {
            transition: all .25s ease;
        }
        &__input-container {
            position: absolute;
            left: 0;
            top: -.8rem;
            display: flex;
            align-items: center;
            padding-top: 1.6rem;
            padding-bottom: 1rem;
            transition: all .25s ease;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        }
        .form__input {
            width: 18rem;
            margin-right: 2rem;
            padding-top: .7rem;
            padding-bottom: .7rem;
        }
        &.is-active {
            .pc__input-container {
                visibility: visible;
                opacity: 1;
                pointer-events: auto;
            }
            .pc__button {
                visibility: hidden;
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    &--removeSellPrice {
        &:after {
            display: none;
        }
    }
}

.quote-table-consultant {
    border-right: 1px solid $quote-table-border;
    border-top-right-radius: 5px;

    &:after {
        display: none;
    }

    .quote-table__row {
        &:last-child {
            font-size: unset;
            color: unset;

            > div {
                padding-top: 1rem;   
            }
        }
    }
}

.quote-table__sell-price {
    position: absolute;
    right: -1rem;
    top: -4.8rem;
    bottom: 222px;
    width: calc(22% + 1.6rem);
    border-radius: 3px;
    border: 1px solid rgba(231, 230, 230, 0.5);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}