$button-size: 6rem;
$button-transition : all .3s ease;

.button {
	appearance: none;
	@include f-btn();
	text-decoration: none;
	padding: 1.4rem 2rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 2.4rem;
	transition: background .3s ease, color .3s ease, border .3s ease;
	cursor: pointer;
	user-select: none;
	outline: 0;
	&__icon {
		width: 1.6rem;
		height: 1.6rem;
		position: relative;
		& + .button__text {
			margin-left: 1rem;
		}
		svg {
			display: block;
		}
	}
	&__text {
		& + .button__icon {
			margin-left: 1rem;
		}
	}
	&:disabled,
	&.disabled,
	&.is-disabled {
		background-color: $grey-2;
		color: rgba(98, 100, 105, .3);
		border: 1px solid $grey-2;
		pointer-events: none;
	}
}

// Primary
.button--1 {
	background-color: $brand-1;
	color: white;
	border: 1px solid $brand-1;
	&:hover {
		background-color: $brand-1-hover;
	}
}

// Secondary
.button--2 {
	background-color: $brand-2;
	color: white;
	border: 1px solid $brand-2;
	&:hover {
		background-color: $brand-2-hover;
	}
	&:disabled,
	&.disabled,
	&.is-disabled {
		background-color: $grey-2;
		color: rgba(98, 100, 105, .3);
		border: 1px solid $grey-2;

		&:hover {
			background-color: $grey-2;
		}
	}
}

// Tertiary
.button--3 {
	background-color: $brand-3;
	color: $grey-6;
	border: 1px solid $brand-3;
	&:hover {
		color: $body-colour;
		background-color: $brand-3-hover;
	}
}

// Outline
.button--outline {
	color: $brand-1;
	border: 1px solid $brand-1;
	// background-color: $grey-1;
	background-color: rgba(0, 0, 0 ,0);
	&:hover {
		color: $brand-1-hover;
		border: 1px solid $brand-1-hover;
	}
}

// CTA 
.button--cta {
	position: relative;
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-radius: 3rem;
	border: 1px solid $grey-1;
	color: $grey-6;
	background-color: $grey-1;
	justify-content: flex-start;
	padding-left: 5.6rem;
	max-width: 406px;
	&:hover {
		border: 1px solid $brand-1;
		color: white !important;
		background-color: $brand-1;
	}
	.button__icon-cta {
		position: absolute;
		top: 50%;
		left: 2rem;
		margin-top: -12px;
		span {
			font-size: 2.4rem;
		}
	}
	.button__icon {
		position: absolute;
		top: 50%;
		right: 2rem;
		width: 16px;
		height: 16px;
		margin-top: -8px;
		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.button--back,
.button--forward {
	border: 0;
	outline: 0;
	position: relative;
	padding-left: 1.6rem;
	padding-right: 0;
	border: 0;
	background-color: rgba(0, 0, 0, 0);
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: inherit;
	appearance: none;
	&:before {
        position: absolute;
        left: 0;
        top: 50%;
		margin-top: -4px;
        content: '';
        display: block;
        width: 6px;
        height: 8px;
        background-image: $arrow-dark;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.button--forward {
	padding-left: 0;
	padding-right: 1.6rem;
	&:before {
		left: auto;
		right: 0;
		transform: rotate(-180deg);
	}
}

.button--clear {
	padding-right: 1rem;
    padding-left: 1rem;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    color: #9FA0A4;
    font-size: 1.2rem;
	display: inline-flex;
	width: auto !important;
	&:hover,
	&:focus {
		color: black;
	}
}

.button--icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.5rem;
	height: 3.5rem;
	padding: 0;
	cursor: pointer;
}

.button--header-icon {
	border: 0;
	background-color: transparent;
	color: $grey-6;
	padding: .5rem;
	font-size: $f-sm;
	.button__icon {
		width: 3rem;
		height: 3rem;
		border: 1px solid $brand-1;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $brand-1;
	}
	&:hover {
		color: $brand-1-hover;
	}
}

.button--text {
	border: 0;
	padding-left: .4rem;
	padding-right: .4rem;
	background-color: rgba(0, 0, 0, 0);
	color: $grey-5;
	&:hover {
		text-decoration: underline;
		color: black;
	}
}

.button__slider {
	position: relative;
	width: 3.6rem;
	height: 1.6rem;
	border-radius: .8rem;
	margin-right: 1rem;
	border: 1px solid $brand-1;
	&:before {
        position: absolute;
        content: "";
        width: 2rem;
        height: 1.4rem;
        top: 0;
        left: 0;
        border-radius: .7rem;
        background-color: $grey-4;
        transition: $switch-slider-transition;
		transform: translateX(0);
    }
}

.checked {
	.button__slider {
		&:before {
			transform: translateX(1.4rem);
			background-color: $brand-1;
		}
	}
}

.button--view-quote {
	&.disabled {
		pointer-events: all;
	}
}