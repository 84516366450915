// Grid
.col-item {
	padding: 8px;
	color: black;
	background-color: $grid-bg;
	text-align: center;
	margin-bottom: 24px;
}

// Visual grid
.visual-grid {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 15;
	display: none;
	pointer-events: none;
	@if ($showGrid == true) {
		display: block;
	}
	.col-item,
	.row,
	.container {
		height: 100%;
	}
}

body {
	&:before {
		content: "";
		position: fixed;
		background-color: rgba(234, 18, 113, 0.4);
		left: auto;
		right: 0;
        top: 60px;
        z-index: 999;
		width: 100%;
		height: 195px;
		display: none;
		pointer-events: none;
        @if ($bodyRuler == true) {
            display: block;
        }
	}
}