$flyout-y-padding          : 5rem;
$flyout-x-padding          : 6rem;

.flyout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $flyout-index;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    opacity: 0;
    pointer-events: none;
    transition: all .4s ease;
    background-color: $overlay-bg;
    
    .flyout-container {
        width: 100%;
        max-width: 602px;
        height: 100%;
        max-height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        transform: translateX(100%);
        transition: all .4s ease;
    }

    &.is-open {
        opacity: 1;
        pointer-events: auto;
        .flyout-container {
            transform: translateX(0);
        }
    }
}

.loose-items-is-open {
    overflow: hidden;
    .flyout {
        opacity: 1;
        pointer-events: auto;
        .flyout-container {
            transform: translateX(0);
        }
    }
}

.flyout__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-left: $flyout-x-padding;
    padding-right: $flyout-x-padding;
    background: linear-gradient(180deg, rgba(250, 250, 250, 0.5) 0%, rgba(243, 242, 242, 0.5) 100%);
    border: 1px solid $grey-2;
    height: 140px;
}
.flyout__title {
    display: flex;
    align-items: center;
    color: black;
    margin-bottom: 0;
    padding-right: 2rem;
    &-count {
        @include display-center();
        line-height: 1;
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        font-size: $f-sm;
        color: $brand-1;
        background-color: $brand-1-light;
        margin-top: 1px;
        margin-left: 1rem;
        font-family: $f2;
        font-weight: normal;
    }
}

.flyout__body {
    @include custom-scrollbar();
    flex-grow: 1;
    padding: 3.2rem $flyout-x-padding $flyout-y-padding $flyout-x-padding;
}