.breakers {
    width: 100%;
    padding-top: 0rem;
    margin-top: -.4rem;
}

.breaker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 6.2rem;
    padding: .6rem 1rem;
    margin-bottom: 2rem;
    border: 1px solid rgba(98, 100, 105, .1);
    border-radius: 5px;
    background-color: rgba(203, 203, 203, .1);
    font-size: $f-sm;
    transition: all .35s ease;
    @include media-breakpoint-up(xl) {
        padding: .6rem 1.6rem;
    }
    &__number {
        @include display-center();
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 1.6rem;
        border: 1px solid $grey-7;
        border-radius: 50%;
        line-height: 1;
        flex-shrink: 0;
        font-size: $f-xs;
        @include media-breakpoint-up(xl) {
            width: 3rem;
            height: 3rem;
            margin-right: 2rem;
            font-size: $f-sm;
        }
    }
    &__type {
        position: relative;
        display: block;
        flex-shrink: 0;
        width: 28px;
        margin-right: 1.6rem;
        @include media-breakpoint-up(xl) {
            width: 32px;
            margin-right: 2rem;
        }
        &--1 {
            .pole-ui:nth-child(1) {
                display: block !important;
            }
        }
        &--2 {
            .pole-ui:nth-child(1),
            .pole-ui:nth-child(2) {
                display: block !important;
            }
        }
        &--3 {
            .pole-ui:nth-child(1),
            .pole-ui:nth-child(2),
            .pole-ui:nth-child(3) {
                display: block !important;
            }
        }
        &--4 {
            .pole-ui:nth-child(1),
            .pole-ui:nth-child(2),
            .pole-ui:nth-child(3),
            .pole-ui:nth-child(4) {
                display: block !important;
            }
        }

        &--5 {
            .pole-ui:nth-child(1),
            .pole-ui:nth-child(2),
            .pole-ui:nth-child(3),
            .pole-ui:nth-child(4),
            .pole-ui:nth-child(5) {
                display: block !important;
            }
        }
        &--6 {
            .pole-ui {
                display: block !important;
            }
        }
        &--8 {
            width: 7rem !important;
            .pole-ui {
                display: block !important;
            }
        }
        .pole-ui {
            display: none;
            height: 4px;
            border-radius: 3px;
            background-color: $grey-6;
            @include media-breakpoint-up(xl) {
                height: 5px;
            }
            & + .pole-ui {
                margin-top: 3px;
            }
        }
    }
    &__title {
        flex-grow: 1;
        font-size: $f-xs;
        @include media-breakpoint-up(xl) {
            font-size: $f-sm;
        }
        .is-auto-placement & {
            font-size: $f-xs;
            @include media-breakpoint-up(xxl) {
                font-size: $f-sm;
            }
        }
    }
    &__actions {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        .button--icon {
            @include display-center();
            width: 3.2rem;
            height: 3.2rem;
            border: 0;
            background-color: rgba(0, 0, 0, 0);
        }
    }
    .is-drag-drop & {
        &:hover {
            border: 1px solid $brand-1-hover;
            color: $brand-1-hover;
            background-color: $brand-1-extra-light;
            .breaker__number {
                border: 1px solid $brand-1-hover;
            }
            .breaker__type {
                .pole-ui {
                    background-color: $brand-1-hover;
                }
            }
        }
    }
    .is-disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}

.breaker__subtitle {
    color: $error-colour;
    padding-top: 5px;
}

// Residential Specific updates
.is-residential {

    .breaker {
        &__number {
            margin-right: 1rem;
        }
        &__type {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 2.4rem;
            width: 4.2rem;
            margin-right: 1.2rem;
            .pole-ui {
                width: .5rem;
                height: 2.4rem;
                margin-top: 0;
                margin-left: .4rem;
                &:first-child {
                    margin-left: 0;
                }

            }
        }
    }
}

//--------------------------------

.favourite {
    position: relative;

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        &:checked {
            & ~ .favourite__label {
                .i-favourite-selected {
                    opacity: 1;
                }
            }
        }
    }
    &__label {
        @include display-center();
        position: relative;
        width: 3.2rem;
        height: 3.2rem;
        cursor: pointer;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2rem;
            height: 2rem;
            margin-top: -1rem;
            margin-left: -1rem;
            font-size: 2rem;
            &.i-favourite-selected {
                color: $red;
                opacity: 0;
            }
        }
    }
}

// Auto placment button
.button-auto-placement {
    padding-top: .4rem;
    padding-bottom: .4rem;
    padding-left: .4rem;
    padding-right: .4rem;
    font-size: $f-xs;
    align-items: center;
    line-height: 1;
    margin-left: .6rem;
    .button__icon {
        width: 1.2rem;
        height: 1.2rem;
        & + .button__text {
            margin-left: .4rem;
        }
    }
    @media (max-width: 1180px) {
        padding: 0;
        width: 2.6rem;
        height: 2.6rem;
        .button__text {
            display: none;
        }
    }   
    .is-drag-drop & {
        display: none;
    }
}

.breaker__quantity {
    width: 38px;
    > input {
        padding: 0.8rem;
        text-align: center;
    }
}