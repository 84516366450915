.form--register-landing {
    padding-top: 2.4rem;
}
.resgister__types {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    .button + .button {
        margin-top: 1.2rem;
    }
}