html {
    height: 100%;
}

.layout {
    height: 100%;
}

#root {
    height: 100%;
}

.app {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.app__header {
    background-color: white;
    flex-shrink: 0;
    border-bottom: 2px solid $grey-2;
}

.app__body {
    display: flex;
    background-color: $grey-1;
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
    .board-message-mute {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    &--boards {
        .app__body-content {
            padding-bottom: 6rem;
        }
    }
}

.app__body-content {
    @include custom-scrollbar();
    @include x-padding();
    width: 100%;
    .app__body--subpage & {
        padding-left: 0;
        display: flex;
    }
}

.app__footer {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 100px;
    border-top: 1px solid $grey-3;
    background-color: $grey-1;
    padding-left: 1rem;
    padding-right: 1rem;
    .step {
        min-width: 114px;
        .em {
            display: inline-block;
        }
    }
    .step__number {
        flex-shrink: 0;
    }
    .step__text {
        height: 1.4rem;
    } 
}


// ------------------
// Switchboard layout.

// SIDEBAR
.board__sidebar {
    @include x-padding();
    @include custom-scrollbar();
    display: flex;
    flex-direction: column;
    flex: 0 0 36.4%;
    max-width: 36.4%;
    .is-residential & {
        flex: 0 0 32%;
        max-width: 32%;
    }
    padding-top: 1.6rem;
    padding-bottom: 3rem;
    // Uncomment if need to make specific to safari 13
    // @media (max-height: 840px) {
    //     padding-bottom: 1rem;
    // }
    box-shadow: 0px 5px 20px rgba(98, 100, 105, 0.02);
    border-right: 2px solid $grey-2;
    background-color: white;
    @include media-breakpoint-up(lg) {
        padding-right: 2.175vw;
    }
    @include media-breakpoint-up(xl) {
        padding-right: 2.8vw;
    }
    @include media-breakpoint-up(xxl) {
        padding-left: $x-xxl;
        padding-right: 3vw;
    }
    .breadcrumbs {
        padding-top: 3rem;
        // Uncomment if need to make specific to safari 13
        // @media (max-height: 840px) {
        //     padding-top: 1rem;
        // }
    }
}

.sidebar__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 3rem;
    // Uncomment if need to make specific to safari 13
    // @media (max-height: 840px) {
    //     padding-top: 1rem;
    // }
    .button {
        margin-top: .5rem;
        margin-bottom: .5rem;
        margin-right: 1.5rem;
    }

    :not(.is-residential) & {
        justify-content: space-between;

        .button {
            margin-right: 0;
        }
    }
}

.sidebar__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-grow: 1;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    // Uncomment if need to make specific to safari 13
    // height: calc(100% - 422px);
    // padding-bottom: 1rem;
    // @include custom-scrollbar();
    // padding-right: 1.2rem;
    // margin-right: -1.2rem;
    // @media (max-height: 840px) {
    //     height: calc(100% - 362px);
    // }
    .board-message-mute {
        padding-top: 2vh;
        padding-bottom: 2vh;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.sidebar__footer {
    display: flex;
    align-items: flex-end;
    padding-bottom: 3rem;
    // Uncomment if need to make specific to safari 13
    // border-top: 1px solid $grey-2;
    // padding-top: 2.6rem;
    // @media (max-height: 840px) {
    //     padding-bottom: 1rem;
    // }
    &-inner {
        width: 100%;
    }
    .switch {
        margin-bottom: 2.5rem;
    }
}

.breaker-hack-space {
    padding-bottom: 1rem;
}
.sidebar__footer-bottom {
    padding-top: 2.5rem;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $grey-2;
    .h4,
    .button {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    &__title {
        display: flex;
        align-items: center;
        color: black;
        margin-bottom: 0;
        padding-right: 2rem;
        @media (max-width: 1100px) {
            font-size: 2rem !important;
        }
        &-count {
            @include display-center();
            line-height: 1;
            width: 2.8rem;
            height: 2.8rem;
            border-radius: 50%;
            font-size: $f-sm;
            color: $brand-1;
            background-color: $brand-1-light;
            margin-top: 1px;
            margin-left: 1rem;
            font-family: $f2;
            font-weight: normal;
        }
    }
    .button--outline {
        @media (max-width: 1100px) {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
        }
    }
}
.sidebar__title {
    margin-bottom: 0;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

// Sidebar custom UI 
.switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 2rem;
    border: 1px solid $grey-2;
    position: relative;
    height: 4rem;
    cursor: pointer;
    &__button {
        display: flex;
        align-items: center;
        padding-left: 1.4rem;
        padding-right: 1.4rem;
        flex: 0 0 calc(50% - 39px);
        max-width: calc(50% - 39px);
        color: $grey-4;
        transition: .3s;
        user-select: none;
        white-space: nowrap;
        @include media-breakpoint-down(lg) {
            flex: 0 0 calc(50% - 30px);
            max-width: calc(50% - 30px);
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @include media-breakpoint-up(xxl) {
            padding-left: 1.8rem;
            padding-right: 1.8rem;
        }
        &:first-of-type {
            text-align: left;
        }
        &:last-of-type {
            justify-content: flex-end;
        }
        &-text {
            font-size: $f-sm;
            padding-left: .8rem;
            @include media-breakpoint-down(xl) {
                font-size: $f-xs;
            }
            @include media-breakpoint-down(lg) {
                padding-left: 0;
            }
        }
        &-icon {
            @include media-breakpoint-down(lg) {
                font-size: 1.4rem;
                display: none;
            }
        }
        &--on {
            color: $grey-6;
        }
    }
}
.switch__input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
.switch__slider {
    position: relative;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $grey-1;
    border: 1px solid $grey-3;
    border-radius: 1.7rem;
    transition: $switch-text-transition;
    width: 7.8rem;
    height: 3.4rem;
    
    @include media-breakpoint-down(lg) {
        width: 6rem;
    }
    &:before {
        position: absolute;
        content: "";
        width: 4.5rem;
        height: 3.2rem;
        top: 0;
        left: 0;
        border-radius: 1.7rem;
        background-color: $grey-6;
        transition: $switch-slider-transition;
        @include media-breakpoint-down(lg) {
            width: 3.8rem;
        }
    }
}
  
.switch__input:checked {
    ~ .switch__button {
        &--on {
            color: $grey-4;
        }
        &--off {
            color: $grey-6;
        }
    }
}

.switch__input:checked ~ .switch__slider:before {
    transform: translateX(3.2rem);
    background-color: $grey-6;
    @include media-breakpoint-down(lg) {
        transform: translateX(2.4rem);
    }
}

// For project homepage or can be placed in header title.
// to switch between COM / RES
.config-switch {
    position: relative;
    display: inline-flex;
    height: 4.8rem;
    margin-left: 2rem;
    border-radius: 2.4rem;
    cursor: pointer;
    .switch__button-icon {
        font-size: 1.8rem;
    }
    .switch__slider {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 2.4rem;
        &:before {
            width: 50%;
            height: 100%;
            border-radius: 2.4rem;
        }
    }
    .switch__button {
        position: relative;
        z-index: 2;
        flex: 0 0 50%;
        max-width: 50%;
        color: $grey-5;
        &--on {
            color: white;
        }
    }
    .switch__input:checked {
        ~ .switch__slider:before {
            transform: translateX(100%);
        }
        ~ .switch__button {
            &--off {
                color: white;
            }
            &--on {
                color: $grey-5;
            }
        }
    } 
}

// END Sidebar
.row--sm-gutter {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
}
.col--sm-gutter {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

// Special columns  // custom

.col--sm {
    flex: 0 0 24%;
    max-width: 24%;
}
.col--md {
    flex: 0 0 38%;
    max-width: 38%;
}

// Sub pages not app
.subpage-content {
    padding-top: 4rem;
    padding-bottom: 5.6rem;
    background-color: white;
    height: fit-content;
    padding-left: $x-sm;
    @include media-breakpoint-up(md) {
        padding-left: $x-md;
    }
    @include media-breakpoint-up(lg) {
        padding-left: $x-lg;
        flex: 0 0 75%;
        max-width: 75%;
        padding-right: 6.4rem;
    }
    @include media-breakpoint-up(xl) {
        padding-left: $x-xl;
        flex: 0 0 62%;
        max-width: 62%;
    }
    @include media-breakpoint-up(xxl) {
        padding-left: $x-xxl;
    }
    
    .row {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .col {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .form__actions {
        justify-content: flex-start;
        .button {
            margin-left: 0;
        }
    }
    &__width {
        max-width: 588px;
    }
}

// FAQs content
.faqs-content {
    padding-top: 6rem;
    padding-left: 6rem;
    width: 100%;
}   