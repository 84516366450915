@import 'quote-table';

.quote-bg {
    background-color: white;
    box-shadow: 0px 5px 20px rgba(98, 100, 105, 0.02);
    border-radius: 3px;
    padding: 3.6rem 3.6rem 8rem 3.6rem;
    p {
        color: $grey-6;
    }
}

.quote-layout {
    display: flex;
    flex-wrap: wrap;
    padding-top: 6rem;
    padding-bottom: 8rem;
}

.l_quote-col {
    &:first-child {
        flex: 0 0 100%;
        max-width: 100%;
        @media (min-width: 1360px) {
            flex: 0 0 75%;
            max-width: 75%;
        }
        @media (min-width: 1600px) {
            flex: 0 0 70.75%;
            max-width: 70.75%;
        }
    }
    &:last-child {
        flex: 0 0 100%;
        max-width: 100%;
        @media (min-width: 1360px) {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @media (min-width: 1600px) {
            flex: 0 0 29.25%;
            max-width: 29.25%;
        }
    }
}

.quote__review {
    padding-top: 3rem;
    @media(min-width: 1360px) {
        padding-left: 3vw;
    }
    @media(min-width: 1440px) {
        padding-top: 4rem;
        padding-left: 4.4vw;
    }
    @media(min-width: 1600px) {
        padding-left: 8.6rem;
    }
    p,
    .form__label {
        color: $grey-6;
        .em {
            color: black;
        }
    }
    .form__input,
    .select--custom {
        max-width: 232px;
        font-size: $f-sm;
    }

    button[type="submit"] {
        margin-top: 12px;
    }
    .button + .h4 {
        margin-top: 4rem;
    }
    .form {
        padding-top: 1rem;
    }
    .form__group {
        margin-bottom: 2rem;
    }
}

.quote__review-step {
    padding-top: 4rem;
    &.has-border {
        border-top: 1px solid $grey-3;
        padding-top: 1.6rem;
        margin-top: 4rem;
    }
    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .form-label-step {
            margin-bottom: 0;
            &__text {
                margin-top: 2px;
                padding-right: 1rem;
            }
        }
        .button--clear {
            font-size: 1.6rem;
            padding-left: 0;
            padding-right: 0;
            color: black;
            &:hover {
                color: $grey-5;
            }
            .button__icon {
                margin-left: 1rem;
            }
        }
    }
}

