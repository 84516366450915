.switchboard__res-header {
    color: $grey-6;
    padding-top: 2rem;
    .donut-container {
        padding-top: 0;
        padding-right: 1.2rem;
        margin-left: -.7rem;
    }
    .donut {
        width: 8.6rem;
        height: 8.6rem;
        border-width: .8rem;
    }
    .button {
        margin-left: 2rem;
    }
    .h4 {
        position: relative;
        color: inherit;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        max-width: calc(100% - 100px);
    }
}
.switchboard__res-header__item {
    display: flex;
    align-items: center;
    overflow: hidden;
    &:first-child {
        flex-grow: 1;
    }
    &:last-child {
        flex-shrink: 0;
    }
}

.res-header__row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $grey-2;
    padding-bottom: 1rem;
    .switchboard__board-header {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
    @include media-breakpoint-up(lg) {
        margin-left: -2rem;
        margin-right: -2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    
    @include media-breakpoint-up(xl) {
        margin-left: -4rem;
        margin-right: -4rem;
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

