$modal-y-padding          : 5rem;
$modal-x-padding          : 5.6rem;

.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: $modal-index;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all .5s ease;
    background-color: $overlay-bg;
    .space {
        padding-top: 6vh;
        padding-bottom: 6vh;
    }
    .modal-container {
        width: 100%;
        max-width: 954px;
        max-height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
    }
    .modal__close {
        position: absolute;
        top: 2.8rem;
        right: 2.8rem;
        border: 0;
        background-color: rgba(0, 0, 0, 0);
        color: $grey-5;
    }
    &--sm {
        .modal-container {
            max-width: 680px;
        }
        .modal__header {
            padding-top: 2rem;
            padding-bottom: 2rem;
            height: 13.4rem;
            display: flex;
            align-items: center;
        }
        .modal__body {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }
    &--md {
        .modal-container {
            max-width: 740px;
        }
    }
    .form__label {
        font-size: $f-sm;
    }
}

.modal-is-open {
    overflow: hidden;
    .modal {
        opacity: 1;
        pointer-events: auto;
    }
}

.modal__header {
    position: relative;
    padding-left: $modal-x-padding;
    padding-right: $modal-x-padding;
    padding-top: calc(#{$modal-y-padding});
    padding-bottom: $modal-y-padding;
    background: linear-gradient(180deg, rgba(250, 250, 250, 0.5) 0%, rgba(243, 242, 242, 0.5) 100%);
    border: 1px solid $grey-2;
    @media (min-height: 780px) {
        padding-top: calc(#{$modal-y-padding} + 1rem);
    }
    .modal__title {
        color: black;
        margin-bottom: 0;
        padding-right: 2rem;
        & + .steps {
            position: relative;
            bottom: -2rem;
        }
    }
}

.steps-progress {
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    height: 5px;
    z-index: 1;
    .steps-progress-bar {
        background-color: $brand-1;
        height: 100%;
        width: 20%;
        border-radius: 0 5px 5px 0;
    }
}

.modal__body {
    @include custom-scrollbar();
    flex-grow: 1;
    padding: 4.4rem $modal-x-padding;
    .modal__footer {
        height: 7rem;
        padding-bottom: 1rem;
    }
}

.modal__footer {
    height: 7.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    @media (min-height: 780px) {
        height: 8rem;
    }
    .button {
        & + .button {
            margin-left: 3.2rem;
        }
    }
}

.modal__validation-msg {
    color: $warning-colour;
    max-width: 400px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;

    a {
        color: $alert-warning;
    }
}

.availability-validation-messagges {
    margin: 2rem 0;

    a {
        color: $alert-warning;
    }
}

// Email modals
.email-quote__lead {
    padding-bottom: 1rem;
    .h5 {
        font-family: $f1;
        font-weight: 300;
    }
}

// Add board specific
.add-board-modal {
    .modal__footer {
        border-top: 1px solid $grey-2;
    }
    input {
        font-size: $f-sm;
    }
    .modal__body {
        height: 100%;
        height: clamp(56vh, 660px, 80vh);
    }
}