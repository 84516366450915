@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.button {
    .button__loader-text,
    .button__loader {
        display: none;
    }
    
    &.is-loading {
        transition: all .5s ease;
        background-color: $grey-2;
		color: rgba(98, 100, 105, .3);
		border: 1px solid $grey-2;
		pointer-events: none;
        .button__icon {
            display: none;
        }
        .button__loader-text,
        .button__loader {
            display: block;
        }
        &:before {
            background-image: none;
        }
    }
}

.button--secondary {
    .button__loader-text {
        padding-right: 2.8rem;
        padding-left: 2rem;
    }
    .button__loader {
        margin-right: 19px;
        color: #ffffff;
    }
    &.is-loading {
        &:focus {
            &:before {
                width: 6rem;
            }
        }
    }
}

.button {
    &__loader {    
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-bottom: 2px;
        position: relative;
        &:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 16px;
            height: 16px;
            margin-top: -8px;
            margin-left: -8px;
            border-radius: 50%;
            border: 2px solid transparent;
            border-top-color: currentColor;
            border-right-color: currentColor;
            border-bottom-color: currentColor;
            animation: spinner .8s linear infinite;
        }
    }
}

.spinner {
    display: block;
        width: 100%;
        height: 100%;
        margin-bottom: 2px;
        position: relative;
        color: $brand-1;
        &:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 32px;
            height: 32px;
            margin-top: -16px;
            margin-left: -16px;
            border-radius: 50%;
            border: 2px solid transparent;
            border-top-color: currentColor;
            border-right-color: currentColor;
            border-bottom-color: currentColor;
            animation: spinner .8s linear infinite;
        }
}