@import 'responsive-div';
@import 'hide-text';
@import 'btn-icon';
@import 'custom-scrollbar';
@import 'display-center';


@mixin cb-padding-default {
	padding-top: $cb-padding-sm;
	padding-bottom: $cb-padding-sm;
	@include media-breakpoint-up(md) {
		padding-top: $cb-padding-md;
		padding-bottom: $cb-padding-md;
	}
	@include media-breakpoint-up(xl) {
		padding-top: $cb-padding-lg;
		padding-bottom: $cb-padding-lg;
	}
	&:last-child {
		padding-bottom: $cb-padding-sm + $cb-padding-sm;
		@include media-breakpoint-up(md) {
			padding-bottom: $cb-padding-md + $cb-padding-md;
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: $cb-padding-lg + $cb-padding-lg;
		}
	}
}

@mixin tb-padding-default {
	padding-top: $tb-padding-sm;
	padding-bottom: $tb-padding-sm;
	@include media-breakpoint-up(md) {
		padding-top: $tb-padding-md;
		padding-bottom: $tb-padding-md;
	}
	@include media-breakpoint-up(xl) {
		padding-top: $tb-padding-lg;
		padding-bottom: $tb-padding-lg;
	}
	&:last-child {
		padding-bottom: 10rem;
		@include media-breakpoint-up(xl) {
			padding-bottom: 12rem;
		}
	}
}

@mixin tile-shadow() {
    box-shadow: 3.34px 3.34px 16.72px 0px rgba(0,0,0,0.05);
}

@mixin block-header() {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 2rem;
	@include media-breakpoint-up(md) {
		padding-bottom: 3vw;
	}
	@include media-breakpoint-up(xxl) {
		padding-bottom: 4.5rem;
	}
}

// Button hover
@mixin nav-hover() {
	transition: all .35s ease;
	&:hover,
	&:focus {
		color: $brand-1;
	}
}

@mixin x-padding() {
    padding-left: $x-sm;
    padding-right: $x-sm;
    @include media-breakpoint-up(md) {
        padding-left: $x-md;
        padding-right: $x-md;
    }
    @include media-breakpoint-up(lg) {
        padding-left: $x-lg;
        padding-right: $x-lg;
    }
    @include media-breakpoint-up(xl) {
        padding-left: $x-xl;
        padding-right: $x-xl;
    }
    @include media-breakpoint-up(xxl) {
        padding-left: $x-xxl;
        padding-right: $x-xxl;
    }
}

// Max- width
@mixin max-width($road: false) {
	@if $road {
		max-width: 772px;
	}
}



@mixin plus-minus-button() {
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	width: 2.4rem;
	height: 3.6rem;
	padding: 0;
	background-color: rgba(0, 0, 0, 0);
	transition: all .35s ease;
	.button__icon {
		background-color: $brand-1;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transition: all .35s ease;
		span {
			font-size: 1.4rem;
		}
	}
	&:hover,
	&:focus {
		.button__icon {
			background-color: $brand-1-hover;
		}
	}
	&:disabled {
		opacity: 0;
		visibility: hidden;
	}
}