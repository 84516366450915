.register__success {
    max-width: 448px;
    &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
    }
    &-title {
        color: $brand-1;
        display: flex;
        align-items: center;
        .i-check-circle {
            font-size: 4.7rem;
            margin-left: 1.6rem;
        }
    }
    &-lead {
        color: $brand-1;
        padding-top: 1.8rem;
    }
    &-text {
        font-family: $f1;
        font-weight: 300;
    }
    .button {
        margin-top: 2rem;
        display: inline-flex;
    }
}