.switchboard__board-header {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    color: $grey-5;
    font-size: $f-sm;
    span {
        user-select: none;
    }
    > div {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .is-residential & {
        padding-left: 0;
        padding-right: 0;
    }
}
.switchboard__poles-configured-text {
    position: relative;
    color: $brand-1;
    padding-left: 2.4rem;
    padding-right: 2rem;
    .i-switchboard {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1.2rem;
        font-size: 2rem;
    }
}

.switchboard__poles-info {
    display: flex;
}