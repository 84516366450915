.quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 10rem;
    &__amount {
        position: relative;
        margin-left: 2px;
        margin-right: 2px;
        height: 36px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &-input {
            width: 3.4rem;
            input {
                border: 0;
                border-bottom: 1px solid $grey-3;

                &:read-only {
                    border-bottom: none;
                }
            }
        }
        &-text {
            color: $grey-6;
        }
        .input {
            padding-top: .4rem;
            padding-bottom: .4rem;
            padding-left: .2rem;
            padding-right: .2rem;
            text-align: center;
        }
        &.is-active {
            .margin-control__amount-input {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
    .button--margin {
        width: 2.8rem;
    }
    .button {
        &.is-disabled {
            background: rgba(0, 0, 0, 0);
            border: 0;
            .button__icon {
                background: $grey-2;
            }
        }
    }
    .button__icon {
        width: 2.6rem;
        height: 2.6rem;
    }
}