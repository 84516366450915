.wholesaler-lookup__flyout {
    .flyout-container {
        max-width: 100%;
        @include media-breakpoint-up(md) {
            max-width: 602px;
        }
    }
    .flyout__header {
        padding: 0 16px;
        height: 175px;
        @include media-breakpoint-up(md) {
            padding: 0 60px;
        }
    }

    .flyout__body {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        gap:28px;
        padding: 40px 16px;

        .form__section,
        .form__actions {
            padding: 0;
            .button {
                margin: 0;
            }
        }

        .form__section{
            flex-grow: 1;
            .form__group {
                margin: 0;
            }
        }

        .wholesaler-list {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            padding: 40px 60px;
        }
    }

    .flyout__close {
        align-items: center;
        appearance: none;
        background: none;
        border: 0;
        color: #9FA0A4;
        cursor: pointer;
        display: inline-flex;
        font-size: 20px;
        justify-content: center;
        outline: 0;
        padding: 0;
        user-select: none;
        -webkit-user-select: none;
    }

    .loader {
        transform: rotate(-90deg);
        stroke: #3CC856;
        background: transparent;
        position: absolute;
        right: 30px;
        top: calc(50% - (30px / 2));
        height: 30px;
        width: 30px
    }

    .loader circle {
        stroke-dasharray: 130;
        stroke-dashoffset: 130;
        -webkit-animation: dash 2s infinite;
        animation: dash 2s infinite;
        width: 20px
    }
    
    @keyframes dash {
        50% {
            stroke-dashoffset: 0
        }
    
        100% {
            stroke-dashoffset: -130
        }
    }
}

.wholesaler__list {
    display: flex;
    flex-direction: column;
}

.wholesaler,
.wholesaler__trigger {
    background-color: rgba(203,203,203,0.1);
    background-image: none;
    border-radius: 5px;
    border: 1px solid rgba(98,100,105,0.1);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
    font-family: ArialRoundedMT__400;
    font-size: 1.4rem;
    padding: 20px;
    position: relative;
    text-align: left;
    width: 100%;
}

.wholesaler,
.wholesaler + .wholesaler {
    margin-bottom: 16px;
}

.wholesaler__title {
    flex-grow: 1;
    p {
        color: #333;
        margin-bottom: 0;
    }
    span {
        color: #626469;
    }
}

.wholesaler__icon {
    background-color: #3CC856;
    border-radius: 100%;
    color: #FFF;
    padding: 3px;
}
