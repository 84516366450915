.table {
    border: 1px solid $grey-2;
    border-collapse: collapse;
    margin-top: .8rem;
    margin-bottom: 2rem;
    width: 100%;
    thead {
        tr {
            background-color: white;
        }
    }
    th {
        font-size: $f-sm;
        font-weight: normal;
        padding: 1.6rem 1.2rem;
        text-align: left;
        &:first-child {
            width: 8.8rem;
        }
    }
    td {
        // border: 1px solid $grey-2;
        font-size: $f-sm;
        font-weight: normal;
        color: black;
        padding: 2.1rem 2rem;
        border-top: 1px solid $grey-2;
        border-bottom: 1px solid $grey-2;
        &:nth-child(1) {
            border-right: 1px solid $grey-2;
            font-size: $f-xs;
        }
        &:nth-child(2) {
            text-align: center;
            border-right: 1px solid $grey-2;
            font-size: $f-sm;
        }
        &:last-child {
            font-size: $f-xs;
        }
    }
    tr {
        &:nth-child(odd) {
            background-color: $grey-1;
        }
    }
}