.power-loss-header {
    height: 9.4rem;
    padding-top: 2rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    padding-bottom: 2rem;
    background-color: white;

    .power-loss + .power-loss {
        padding-left: 1.8rem;
    }

    &--loading-factor {
        height: 11rem;
    }

    &--loading-factor-extended {
        height: 16rem;

        .alert {
            padding: 10px 15px;
        }
    }
}
.is-recalculating {
    opacity: 0.3;
}
.power-loss-header__layout {
    padding-top: .2rem;
    display: flex;
    .power-loss {
        flex: 0 0 50%;
        max-width: 50%;
        @include media-breakpoint-up(xl) {
            flex: 0 0 calc(50% - 10px);
            max-width: calc(50% - 10px);
        }
        @include media-breakpoint-up(xxl) {
            flex: 0 0 calc(50% - 20px);
            max-width: calc(50% - 20px);
        }
    }
    
    &--loading-factor {
        padding-top: 8px;
    }
}
.power-loss {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: $brand-1;
    &-icon {
        font-size: 2rem;
        margin-right: .4rem;
        @include media-breakpoint-up(xl) {
            padding-right: .8rem;
        }
    }
    &.switchboard__power-loss {
        padding-left: 2rem;
    }
    &.is-error {
        color: $warning-colour;
    }
}
.loading-factor-text {
    color: $grey-6;
}
.power-loss__text {
    display: flex;
    align-items: center;
    padding-right: 1.2rem;
    @include media-breakpoint-up(xl) {
        padding-right: 1.6rem;
    }
}
.power-loss__meter {
    position: relative;
    background-color: $grey-2;
    flex-grow: 1;
    height: .8rem;
    border-radius: .4rem;
    max-width: 18rem;
    &-amount {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: .8rem;
        border-radius: .4rem;
        background-color: $brand-1;
        max-width: 100%;
        .is-error & {
            background-color: $warning-colour;
        }
    }
}