$slot-border-colour                 : $grey-2;

// Default pole sizing for row
html {
    --switchboard-row-slot-width: 6.66666666667%;
}

//--------------------------------
// Switchboard Slots (Board)
//--------------------------------

.is-8-pole {
    --switchboard-row-slot-width: 12.5%;
    .switchboard__row,
    .switchboard__row-extra {
        min-width: 630px;
    }
}
.is-11-pole {
    --switchboard-row-slot-width: 9.09090909091%;
    .switchboard__row,
    .switchboard__row-extra {
        min-width: 630px;
    }
}
.is-12-pole {
    --switchboard-row-slot-width: 8.33333333333%;
    .switchboard__row,
    .switchboard__row-extra {
        min-width: 630px;
    }
}
.is-15-pole {
    --switchboard-row-slot-width: 6.66666666667%;
    .switchboard__row,
    .switchboard__row-extra {
        min-width: 630px;
    }
    @media (max-width: 996px) {
        .scroll-to-see {
            display: flex;
        }
    }
}
.is-17-pole {
    --switchboard-row-slot-width: 5.88235294118%;
    .switchboard__row,
    .switchboard__row-extra {
        min-width: 748px;
    }
    @media (max-width: 1284px) {
        .scroll-to-see {
            display: flex;
        }
    }
}
.is-18-pole {
    --switchboard-row-slot-width: 5.55555555556%;
    .switchboard__row,
    .switchboard__row-extra {
        min-width: 792px;
    }
    @media (max-width: 1358px) {
        .scroll-to-see {
            display: flex;
        }
    }
}

.switchboard__row-container {
    position: relative;
}

.switchboard__res-row {
    position: relative;
    @include custom-scrollbar-horizontal();
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.scroll-to-see {
    position: absolute;
    top: 5.2rem;
    right: 0;
    bottom: 2.6rem;
    z-index: 10;
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 300px;
    padding-top: 8rem;
    padding-right: 2.4rem;
    background: linear-gradient(295deg, #534D4E -83.74%, rgba(84, 84, 84, 0) 61.2%);
    pointer-events: none;
    .p {
        padding-top: 1rem;
    }
}

.switchboard__row-title {
    color: $grey-7;
    margin-bottom: 1.2rem;
}

// Specific for row layout
.switchboard__row {
    position: relative;
    display: flex;
    justify-content: flex-start;
    .switchboard__slot {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 0 0 var(--switchboard-row-slot-width);
        max-width: var(--switchboard-row-slot-width);
        height: 20rem;
        padding: 1.2rem .4rem;
        border: 1px solid $slot-border-colour;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            height: .5rem;
            background-color: $grey-7;
        }
        & + .switchboard__slot {
            border-left: 0;
        }
        &.is-disabled {
            position: relative;
            z-index: 5;
            .switchboard__slot-text {
                padding-right: 0;
            }
            .switchboard__slot-number {
                background-color: rgba(203, 203, 203, .3);
            }
        }
    }
    .switchboard__slot-number {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        font-size: $f-sm;
        @include media-breakpoint-down(xl) {
            width: 2.4rem;
            height: 2.4rem;
            font-size: $f-xs;
        }
    }
    .hover-text,
    .available-text {
        display: block !important;
        padding-top: 1.4rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
        font-size: 1rem;
        line-height: 1.5;
        @include media-breakpoint-down(xl) {
            font-size: .9rem;
        }
    }
    .switchboard__slot-text {
        display: block;
        padding-top: 1.4rem;
        text-align: center;
        font-size: $f-xs !important;
        overflow-wrap: break-word;
        word-wrap: break-word;
        line-height: 1.65;
        @include media-breakpoint-down(xl) {
            font-size: 1rem !important;
        }
        @media (max-width: 1080px) {
            font-size: .8rem !important;
        }
    }
}

.special-slot {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    padding-top: 1.2rem;
    padding-left: .4rem;
    padding-right: .4rem;
    background-color: $grey-3;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        height: .5rem;
        background-color: $grey-7;
    }
    .is-main-switch & {
        &:after {
            background-color: #E74C3C;
        }
    }
    .is-surge-protection & {
        &:after {
            background-color: #FFD100;
        }
    }
    .is-neutral-terminal-block & {
        &:after {
            background-color: $grey-7;
        }
    }
    .is-wiser-energy-gateway & {
        background-color: $grey-1;

        .switchboard__breaker-check {
            color: $grey-6;
            display: flex;
        }

        .breaker-check-container {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        
        &:after {
            background-color: #009530;
        }
    }
    .is-2-slot & {
        width: calc(200% + 2px);
    }
    .is-3-slot & {
        width: calc(300% + 4px);
    }
}

.is-mandatory-mcb {
    .switchboard__breaker {
        background: $grey-3;
        border-color: #F3F2F2;
        

        &:after {
            background-color: #FFD100;
        }
    }
    .switchboard__breaker-actions {
        display: none !important
    }
    .switchboard__breaker-check {
        color: $grey-6;
    }
    .switchboard__breaker-text {
        color: $grey-6;
        font-size: $f-xs !important;
        line-height: 1.65;
        font-family: $f2;
        @include media-breakpoint-down(xl) {
            font-size: 1rem !important;
        }
        @media (max-width: 1080px) {
            font-size: .8rem !important;
        }
    }
}

.is-main-switch,
.is-surge-protection {
    &.is-1-slot {
        .switchboard__breaker-check {
            &:nth-child(1) {
                display: flex;
            }
        }
    }
    
    &.is-2-slot {
        .switchboard__breaker-check {
            &:nth-child(1),
            &:nth-child(2) {
                display: flex;
            }
        }
    }
    
    &.is-3-slot {
        .switchboard__breaker-check {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                display: flex;
            }
        }
    }

    .switchboard__breaker-check {
        color: $grey-6;
    }

    .breaker-check-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
    
.switchboard__row-extra {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    padding: 1rem 1.8rem;
    border: 1px solid $slot-border-colour;
    color: $grey-5;
    &:first-of-type {
        border-bottom: 0;
    }
    &:last-of-type {
        border-top: 0;
    }
    &.busbar-is-active {
        .busbar-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
            background: linear-gradient(99.26deg, #626469 1.52%, #53555A 98.74%);
        }
        .row-extra__text--available {
            visibility: hidden;
            opacity: 0;
        }
    }
}

.busbar-details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: none;
    padding: 1rem 7.6rem 1rem 1.8rem;
    font-family: $f1;
    font-weight: 300;
    transition: all .25s ease;
    @media (hover) {
        padding-right: 1.8rem;
    }
    &.is-1-slot {
        width: var(--switchboard-row-slot-width);
    }
    &.is-2-slot {
        width: calc(var(--switchboard-row-slot-width) * 2);
    }
    &.is-3-slot {
        width: calc(var(--switchboard-row-slot-width) * 3);
    }
    &.is-4-slot {
        width: calc(var(--switchboard-row-slot-width) * 4);
    }
    &.is-5-slot {
        width: calc(var(--switchboard-row-slot-width) * 5);
    }
    &.is-6-slot {
        width: calc(var(--switchboard-row-slot-width) * 6)
    }
    &.is-7-slot {
        width: calc(var(--switchboard-row-slot-width) * 7)
    }
    &.is-8-slot {
        width: calc(var(--switchboard-row-slot-width) * 8)
    }
    &.is-9-slot {
        width: calc(var(--switchboard-row-slot-width) * 9)
    }
    &.is-10-slot {
        width: calc(var(--switchboard-row-slot-width) * 10)
    }
    &.is-11-slot {
        width: calc(var(--switchboard-row-slot-width) * 11)
    }
    &.is-12-slot {
        width: calc(var(--switchboard-row-slot-width) * 12)
    }
    &.is-13-slot {
        width: calc(var(--switchboard-row-slot-width) * 13)
    }
    &.is-14-slot {
        width: calc(var(--switchboard-row-slot-width) * 14)
    }
    &.is-15-slot {
        width: calc(var(--switchboard-row-slot-width) * 15)
    }
    &.is-16-slot {
        width: calc(var(--switchboard-row-slot-width) * 16)
    }
    &.is-17-slot {
        width: calc(var(--switchboard-row-slot-width) * 17)
    }
    &.is-18-slot {
        width: calc(var(--switchboard-row-slot-width) * 18)
    }
    &.is-19-slot {
        width: calc(var(--switchboard-row-slot-width) * 18)
    }
    &.is-20-slot {
        width: calc(var(--switchboard-row-slot-width) * 18)
    }
    &.is-21-slot {
        width: calc(var(--switchboard-row-slot-width) * 18)
    }
    &.is-22-slot {
        width: calc(var(--switchboard-row-slot-width) * 18)
    }
    &.is-23-slot {
        width: calc(var(--switchboard-row-slot-width) * 18)
    }
    &.is-24-slot {
        width: calc(var(--switchboard-row-slot-width) * 18)
    }


    &.is-position-2 {
        left: var(--switchboard-row-slot-width);
    }
    &.is-position-3 {
        left: calc(var(--switchboard-row-slot-width) * 2);
    }
    &.is-position-4 {
        left: calc(var(--switchboard-row-slot-width) * 3);
    }
    &.is-position-5 {
        left: calc(var(--switchboard-row-slot-width) * 4);
    }
    &.is-position-6 {
        left: calc(var(--switchboard-row-slot-width) * 5);
    }
    &.is-position-7 {
        left: calc(var(--switchboard-row-slot-width) * 6);
    }
    &.is-position-8 {
        left: calc(var(--switchboard-row-slot-width) * 7);
    }
    &.is-position-9 {
        left: calc(var(--switchboard-row-slot-width) * 8);
    }
    &.is-position-10 {
        left: calc(var(--switchboard-row-slot-width) * 9);
    }
    &.is-position-11 {
        left: calc(var(--switchboard-row-slot-width) * 10);
    }
    &.is-position-12 {
        left: calc(var(--switchboard-row-slot-width) * 11);
    }
    &.is-position-13 {
        left: calc(var(--switchboard-row-slot-width) * 12);
    }
    &.is-position-14 {
        left: calc(var(--switchboard-row-slot-width) * 13);
    }
    &.is-position-15 {
        left: calc(var(--switchboard-row-slot-width) * 14);
    }
    .row-extra__text {
        &:last-child {
            text-align: right;
        }
    }
    @media (hover) {
        &:hover {
            padding-right: 7.4rem;
            .actions {
                width: 5.6rem;
                padding-right: 1rem;
                opacity: 1;
                .button {
                    pointer-events: auto;
                    opacity: 1;
                    transition: opacity .25s linear .15s;
                }
            }
        }
    }
    .actions {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 5.6rem;
        height: 100%;
        padding: 1rem;
        background-color: $grey-6;
        transition: all .25s ease;
        @media (hover) {
            padding-right: 0;
            opacity: 0;
            width: 0;
        }

        .button {
            border: 0;
            color: white;
            background-color: rgba(0, 0, 0, 0);
            transition: opacity .25s ease;
            cursor: pointer;
            will-change: opacity;
            @media (hover) {
                pointer-events: none;
                opacity: 0;
            }
        }
    }
}
/////////////////////////////////////

.power-tag {
    position: absolute;
    left: 0;
    bottom: -4rem;
    width: 100%;
    height: 4rem;
    &--top {
        top: -4rem;
    }
    &--bottom {
        bottom: -4rem;
    }
    .has-2-slot & {
        width: calc(200% + 1px);
    }
    .has-3-slot & {
        width: 300%;
    }
    .form__control {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 2;
            display: block;
            opacity: 0;
        }
        &:before {
            left: -1px;
            border-left: 1px solid $slot-border-colour;
        }
        &:after {
            right: -1px;
            border-right: 1px solid $slot-border-colour;
        }
    }
    .form__control-label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-left: 1rem !important;
        padding-right: 1rem;
        background-color: #F5FCF7;
        font-size: .8rem;
        user-select: none;
        opacity: 0;
        pointer-events: none;
        @include media-breakpoint-up(xl) {
            font-size: .9rem;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 1rem;
        }
        &:before,
        &:after {
            top: 50% !important;
            left: 50%;
            margin-top: -.9rem;
            margin-left: -.9rem;
            opacity: 0 !important;
        }
    }
    .checkbox .form__control-input:checked ~ .form__control-label {
        background-color: $grey-6;
        opacity: 1;
        span {
            opacity: 0;
        }
        &:before,
        &:after {
            opacity: 1 !important;
        }
    }
}

.is-power-tags {
    .form__control-label {
        opacity: 1;
        pointer-events: auto;
    }
    .form__control {
        &:before,
        &:after {
            opacity: 1;
        }
    }
}

/////////////////////////////////////

// Specific for grid layout
.switchboard__grid {
    .switchboard__slot {
        border-top: 1px solid $slot-border-colour;
        border-left: 1px solid $slot-border-colour;
        border-right: 1px solid $slot-border-colour;
        flex: 0 0 calc(50% - 4px);
        max-width: calc(50% - 4px);
        height: $grid-slot-height;
        align-items: center;
        justify-content: space-between;
        padding: .4rem 1.4rem;
        @include media-breakpoint-up(xl) {
            flex: 0 0 calc(50% - 8px);
            max-width: calc(50% - 8px);
        }
        &:after {
            position: absolute;
            top: -1px;
            content:'';
            display: block;
            background-color: #E74C3C;
            height: calc(100% + 1px);
            width: 8px;
            right: -.9rem;
            border-top: 1px solid $slot-border-colour;
            @include media-breakpoint-up(xl) {
                width: 16px;
                right: -1.7rem;
            }
        }
        &:nth-child(even) {
            &:after {
                display: none;
            }
            .switchboard__slot-text {
                order: 1;
                padding-left: 0;
            }
            .switchboard__slot-number {
                order: 2;
            }
        }
        &:nth-child(3n + 6),
        &:nth-child(3) {
            &:after {
                background-color: white;
            }
        }
        &:nth-child(3n + 6) + .switchboard__slot + .switchboard__slot,
        &:nth-child(5) {
            &:after {
                background-color: #3498DB;
            }
        }
        &:first-child() {
            border-radius: $slot-border-radius 0 0 0;
        }
        &:nth-child(2) {
            border-radius: 0 $slot-border-radius 0 0;
        }
        &:nth-last-child(2) {
            border-bottom: 1px solid $slot-border-colour;
            border-radius: 0 0 0 $slot-border-radius;
        }
        &:last-child() {
            border-bottom: 1px solid $slot-border-colour;
            border-radius: 0 0 $slot-border-radius 0;
        }
        &.is-disabled {
            .switchboard__slot-text-height {
                height: 100%;
            }
        }
    }
    .switchboard__slot-text {
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 1rem;
        padding-left: 1.6rem;
    }
    
    .hover-text,
    .available-text {
        align-items: center;
        padding-left: 2rem;
        height: 100%;
    }
    &.is-disabled {
        background: linear-gradient(269.65deg, #F3F2F2 0.1%, #E5E5E5 130.19%);
        .switchboard__slot-number {
            display: none;
        }
        &:nth-child(odd) {
            &:before {
                left: 1.2rem;
            }
            .switchboard__slot-text {
                padding-left: 3.3rem;
            }
        }
        &:before {
            @include icon();
            width: 2.2rem;
            height: 2.2rem;
            position: absolute;
            content: '\e914';
            font-size: 2.2rem;
            top: 50%;
            right: 1.2rem;
            transform: translateY(-50%);
        }
    }
}

.switchboard__slot {
    position: relative;
    background-color: white;
    display: flex;
    color: $grey-6;
    transition: $slot-transition;
    
    @media (hover) {
        &:hover {
            background-color: $brand-1-extra-light;
            &:not(.is-disabled) {
                .switchboard__slot-text {
                    .hover-text {
                        opacity: 1;
                    }
                    .available-text {
                            opacity: 0 !important;
                    }
                }
            }
        }
    }
    
    &-text {
        position: relative;
        display: flex;
        flex-grow: 1;
        overflow: hidden;
        width: 100%;
        color: $grey-5;
        user-select: none;
        font-size: $f-xs;
        .hover-text,
        .available-text {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            // opacity: 0;
            transition: $slot-text-transition;
        }
    }
    &-number {
        user-select: none;
    }
    &.is-disabled {
        background-color: $grey-3;
        .switchboard__slot-text {
            font-size: $f-sm;
            opacity: 1;
            color: $grey-6;
            padding-right: 2.4rem;
            .available-text {
                opacity: 1 !important;
            }
        }
    }
    &.show-available-slots {
        background-color: rgba(235, 249, 238, .5) !important;
        background-color: $brand-1-extra-light;
        .switchboard__slot-text {
            .available-text {
                opacity: 1;
            }
        }
    }
    &.dragging-on-top {
        background-color: $brand-1  !important;
        opacity: 0.8;

        .available-text {
            color: white;
        }
    }
}