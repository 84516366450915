#global-overlay {
    @include display-center();
    position: fixed;
    top: 0;
    left: 0;
    z-index: $overlay-index;
    width: 100%;
    height: 100%;
    background-color: $overlay-bg;
    font-size: 5em;
    .globalOverlay__message {
        @include display-center();
        flex-direction: column;
        color: white;
    }
    .globalOverlay__message-success-icon {
        @include display-center();
        width: 4rem;
        height: 4rem;
        margin-bottom: 1.2rem;
        border-radius: 50%;
        background-color: $brand-1;
        font-size: 2rem;
    }
    .globalOverlay__message-spinner {
        position: relative;
        @include display-center();
        width: 4rem;
        height: 4rem;
        margin-bottom: 1.2rem;
        border-radius: 50%;
        font-size: 2rem;
    }
    .h3 {
        color: white;
    }
}