@font-face {
    font-family: ArialRoundedMT__300;
    src: url(https://www.se.com/au/en/assets/fonts/ArialRoundedMTforSE-Light_Latin.woff2) format("woff2"), url(https://www.se.com/au/en/assets/fonts/ArialRoundedMTforSE-Light_Latin.woff) format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ArialRoundedMT__400;
    src: url(https://www.se.com/au/en/assets/fonts/ArialRoundedMTforSE-Regular_Latin.woff2) format("woff2"), url(https://www.se.com/au/en/assets/fonts/ArialRoundedMTforSE-Regular_Latin.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ArialRoundedMT__700;
    src: url(https://www.se.com/au/en/assets/fonts/ArialRoundedMTforSE-Bold_Latin.woff2) format("woff2"), url(https://www.se.com/au/en/assets/fonts/ArialRoundedMTforSE-Bold_Latin.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

$f1                 : ArialRoundedMT__300;
$f2                 : ArialRoundedMT__400;
$f3                 : ArialRoundedMT__700;

$fs 			    : $f2, sans-serif;

.f1 {
    font-family: $f1;
}

.f2 {
    font-family: $f2;
}

.f3 {
    font-family: $f3;
}

$f-sm               : 1.4rem;
$f-xs               : 1.2rem;

// Headings
@mixin h1() {
    font-family: $f2;
    font-size: 4.2rem;
    line-height: (49/42);
    font-weight: normal;
    color: black;
}

@mixin h2() {
    font-family: $f1;
    font-size: 3.6rem;
    line-height: (41/36);
    font-weight: 300;
    color: black;
}

@mixin h3() {
    font-family: $f1;
    font-size: 3rem;
    line-height: (34/30);
    font-weight: 300;
    color: black;
}

@mixin h4() {
    font-family: $f1;
    font-size: 2.2rem;
    line-height: (25/22);
    font-weight: 300;
    color: black;
}

@mixin h5() {
    font-family: $f2;
    font-size: 1.8rem;
    line-height: (21/18);
    letter-spacing: 0.01em;
    color: black;
    font-weight: normal;
    &--300 {
        font-family: $f1;
        font-weight: 300;
    }
    &--700 {
        font-family: $f3;
        font-weight: 700;
    }
}

@mixin p() {
    font-size: 1.6rem;
    line-height: (20/16);
    font-weight: normal;
    strong,
    &--700 {
        font-family: $f3;
        font-weight: 700;
    }
    small,
    &--sm {
        font-size: 1.4rem;
        line-height: (20/14);
    }
    &--xs {
        font-size: 1.2rem;
        line-height: (18/12);
    }
    &--caps {
        text-transform: uppercase;
    }
    &--dark {
        color: black;
    }
}

// buttons and navigation
@mixin f-btn() {
    
}

@mixin f-nav() {
   
}

@mixin nano-bold() {
    font-family: $f3;
    font-size: $f-xs;
    font-weight: 700;
}