.tag {
    display: inline-flex;
    padding: .9rem 1.4rem;
    font-size: $f-sm;
    border-radius: .3rem;
    white-space: nowrap;
    &--1 {
        color: $brand-1;
        background-color: $tag-1;
    }
    &--2 {
        color: $brand-2-hover;
        background-color: $tag-2;
    }
}