// Breadcrumbs
.breadcrumbs {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.breadcrumb {
    position: relative;
    text-decoration: none;
    color: $grey-6;
    font-size: $f-xs;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-top: .4rem;
    margin-bottom: .4rem;
    transition: color .35s ease;
    @media (hover) {
        &:hover {
            color: $brand-1-hover;
        }
    }
    @include media-breakpoint-up(xl) {
        font-size: $f-sm;
        margin-right: 1.6rem;
    }
    &:first-child {
        margin-left: 0;
        &:before {
            display: none;
        }
    }
    &:last-child {
        color: $grey-5;
        margin-right: 0;
        &:before {
            &:before {
                background-image: $arrow-light;
            }
        }
    }
    &:before {
        position: absolute;
        left: -1.4rem;
        top: 2px;
        content: '';
        display: block;
        width: 6px;
        height: 8px;
        background-image: $arrow-dark;
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(-180deg);
        @include media-breakpoint-up(xl) {
            left: -1.9rem;
            top: 4px;
        }
    }
}

// Steps
.steps {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
}
.step {
    display: inline-flex;
    align-items: center;
    margin-right: 3rem;
    color: $grey-5;
    font-size: $f-sm;
    &:last-child {
        margin-right: 0;
    }
    &__number {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        font-family: $f3;
        font-weight: 700;
        border-radius: 50%;
        background-color: transparent;
        margin-right: 1rem;
        border: 1px solid $grey-4;
        flex-shrink: 0
    }
    &.is-done {
        color: $grey-6;
        .step__number {
            color: rgba(255, 255, 255, .5);
            background-color: $grey-6;
            border: 1px solid $grey-6;
        }
    }
    &.is-active {
        color: $brand-1;
        .step__number {
            color: $brand-1;
            background-color: $brand-1-light;
            border: 1px solid $brand-1-light;
        }
    }
}
.steps {
    &--progress {
        justify-content: space-evenly;
        width: 100%;
    }
    .step--progress {
        font-size: $f-sm;
        color: $grey-5;
        .step__number {
            background-color: rgba(0, 0, 0, 0);
            border: 1px solid $grey-5;
            color: $grey-5;
            font-size: 1rem;
        }
        .em {
            color: black;
        }
        &.is-done {
            color: $brand-1;
            .step__number {
                background-color: $brand-1-light;
                border: 1px solid $brand-1-light;
                color: $brand-1;
            }
            .em {
                color: $brand-1-hover;
            }
        }
    }
}
