.app__body--register {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    @include media-breakpoint-up(lg) {
        flex-direction:row;
        overflow: hidden;
    }

    .app__register-side {
        position: relative;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: unset;
        padding: 2rem;
        padding-bottom: 24px;
        padding-top: 56px;
        min-height: 460px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../../../img/register.jpg');
        @include media-breakpoint-up(md) {
            padding: 4vh 5.6rem;
            min-height: unset;
        }
        @include media-breakpoint-up(lg) {
            max-width: 48%;
            flex: 1 0 48%;
            padding: 8vh 5.6rem;
            padding-top: 0
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 0;
            display: block;
            background: linear-gradient(21deg, rgba(60,200,86,0.6) 0%, rgba(60,200,86,0) 60%),linear-gradient(0deg, rgba(55,63,65,0.25), rgba(55,63,65,0.5));
            content: ''
        }
    }

    .app__register-content {
        display: flex;
        width: 100%;
        height: 100%;
    }
}