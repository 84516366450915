@mixin custom-scrollbar() {
	scrollbar-width: thin;
	scrollbar-color: $brand-1 $grey-2;
	height: 100%;
	overflow: auto;
	
	&::-webkit-scrollbar {
		width: 5px;
		left: -10px;
	  }
	   
	  &::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
		margin-right: 1rem;
        background-color: $grey-2;
	  }
	   
	  &::-webkit-scrollbar-thumb {
		background-color: $brand-1;
		outline: 0;
		border-radius: 3px;
	  }
}

@mixin custom-scrollbar-horizontal() {
	scrollbar-width: thin;
	scrollbar-color: $brand-1 $grey-2;
	width: 100%;
	overflow: auto;
	
	&::-webkit-scrollbar {
		height: 5px;
		left: -10px;
	  }
	   
	  &::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
		margin-right: 1rem;
        background-color: $grey-2;
	  }
	   
	  &::-webkit-scrollbar-thumb {
		background-color: $brand-1;
		outline: 0;
		border-radius: 3px;
	  }
}