@mixin disabled-input() {
    border-color: $grey-2;
    background-color: $grey-1;
    color: $grey-5;
    pointer-events: none !important;
    user-select: none;
}

@mixin input-base() {
    appearance: none;
    width: 100%;
    padding: 1.3rem 1.2rem;
    border: 1px solid $grey-3;
    border-radius: .3rem;
    outline: 0;
    transition: $input-focus;
    color: $grey-7;
    background-color: white;
    line-height: 1.25;
    font-size: $base-font-size;
    &:disabled,
    &.is-disabled {
        @include disabled-input();
    }
    &:focus {
        border: 1px solid $brand-2;
    }
    .is-error & {
        border: 1px solid $error-colour;
    }
    // ? Not sure how best to tackle this without using JS.
    // &:invalid {
    //     &:not(:empty) {
    //         border: 1px solid $error-colour;
    //     }
    // }
}

.select--custom {
    @include input-base();
    padding-right: 2.4rem;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4.05551 3.9752L1.04266 0.898875L1.04278 0.898764L1.03814 0.894484L1.00805 0.86671L1.00816 0.866591L1.00328 0.862527C0.798738 0.692113 0.494506 0.700877 0.300243 0.891125C0.0930885 1.094 0.0896182 1.42639 0.292492 1.63355L3.68752 5.10021L3.68741 5.10032L3.69189 5.10446L3.72095 5.13138L3.72085 5.13149L3.72555 5.13543C3.93602 5.31203 4.25183 5.29729 4.44442 5.09321L7.71606 1.62655L7.71618 1.62666L7.72027 1.62185L7.74684 1.59069L7.74697 1.5908L7.75083 1.58576C7.91306 1.37467 7.89233 1.07102 7.69458 0.884396C7.48371 0.685389 7.15144 0.695005 6.95244 0.905872C6.95243 0.905874 6.95243 0.905875 6.95243 0.905876L4.05551 3.9752Z' fill='%23626469' stroke='%23626469' stroke-width='0.25'/%3e%3c/svg%3e ");
    background-position: right 12px center;
    cursor: pointer;
    &:focus {
        outline: 0;
    }
    &:invalid {
        color: $grey-6;
    }
}
.select--custom::-ms-expand {
    display: none;
}

// Input
.input {
    @include input-base();
    &:read-only {
        pointer-events: none;
        color: $grey-6;
    }
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.form__group {
    margin-bottom: 2.6rem;
    &--native-validation {
        display: flex;
        flex-direction: column;
        .form__input,
        .form__select {
            order: 2;
        }
        .form__label {
            order: 1;
        }
    }
    input,
    select {
        &.invalid {
            border: 1px solid $error-colour !important;
            & ~ label {
                color: $error-colour;
            }
        }
    }
    ::-webkit-input-placeholder {
        color: $form-placeholder;
    }
    ::-moz-placeholder {
        color: $form-placeholder;
    }
    :-ms-input-placeholder {
        color: $form-placeholder;
    }
    :-moz-placeholder {
        color: $form-placeholder;
    }
}

.form__label {
    display: block;
    margin-bottom: 1rem;
    .is-error & {
        color: $error-colour;
    }
    &-inline-block {
        display: inline-block;
    }
}

.form__group-error {
    color: $error-colour;
    padding-top: .4rem;
    display: none;
    font-size: $f-sm;
    order: 3;
    .is-error & {
        display: block;
    }
}
.invalid ~ .form__group-error {
    display: block;
}


.form__section {
    padding-top: 2.4rem;
}

.form__actions {
    display: flex;
    justify-content: center;
    padding-top: 1.6rem;
    .button {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}


//
// Custom checkbox & Radios
//
.form__control {
    position: relative;
    margin-bottom: .8rem;
    padding-top: .8rem;
    padding-bottom: .8rem;
    cursor: pointer;

    &.radio--button {
        padding-top: .2rem;
        padding-bottom: .2rem;
        margin-bottom: 1rem;

        &.is-disabled {
            pointer-events: none;
            
            > label {
                color: $grey-4
            }
        }
    }

    .form__control-label {
        display: block;
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        color: $body-colour;
        cursor: pointer;
        font-size: $f-sm;
        @include media-breakpoint-down(xs) {
            padding-left: 2.4rem;
        }
        a {
            color: $brand-1;
            text-decoration: none;
        }
        &:not(.button--cta) {
            padding-left: 3.2rem;
            padding-top: .1rem;
        }

        &.button {
            display: flex;
            color: $grey-6;
            font-size: $base-font-size;
            &:before,
            &:after {
                top: 50%;
                left: auto;
                right: 1.6rem;
                transform: translateY(-50%);
                opacity: 0;
            }
            &:not(.button--cta) {
                padding: 1.4rem 3.7rem;
            }
        }
        &.button--cta {
            margin-bottom: 1rem;
        }
        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            content: "";
            color: $grey-5;
        }
        &:before {
            pointer-events: none;
            border: 1px solid $grey-5;
            z-index: 1;
        }
        &:after {
            @include icon();
            content: '\e906';
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
        }
    }
    &.checkbox {
        .form__control-label {
            &:after {
                opacity: 0;
            }
            &:before,
            &:after {
                top: 1px;
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 1px;
            }
        }
    }
}

.form__control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
    &:checked {
        ~ .form__control-label {
            &:after {
                background-color: $brand-1-light;
                color: $brand-1;
                .checkbox & {
                    color: white !important;
                    opacity: 1;
                    background-color: $brand-1
                }
            }
            &:before {
                border: 1px solid $brand-1-light;
                .checkbox & {
                    border: 1px solid $brand-1;
                }
            }
        }
        ~ .form__control-label.button {
            color: white;
            background-color: $brand-1;
            &:after {
                opacity: 1;
                background-color: $brand-1-hover;
                // color: $brand-1;
                color: white !important;
            }
            &:before {
                opacity: 1;
                border: 1px solid $brand-1-hover;
            }
        }
    }
}


.board-extras-col {
    padding-bottom: 2rem;
}
.checkbox {
    &.checkbox--alt {
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
        user-select: none;
        height: 100%;
        margin-bottom: .4rem;
        .form__control-label {
            padding: 1.4rem 1.8rem;
            border: 1px solid $grey-2;
            display: flex;
            flex-direction: column;
            border-radius: .5rem;
            background-color: $grey-1;
            padding-right: 10rem;
            height: 100%;
            .p--xs {
                color: $grey-6 !important;
                margin-bottom: 0;
            }
            .p {
                color: $grey-7;
                line-height: (25/16);
                display: block;
                &:not(.p--xs) {
                    flex-grow: 1;
                    padding-bottom: .2rem;
                }
            }
            &:before,
            &:after {
                left: auto;
                top: 1.8rem;
                right: 1.8rem;
                border-radius: 50%;
                width: 3rem;
                height: 3rem;
                opacity: 1;
            }
            &:after {
                font-size: 1.5rem;
            }
        }
        .form__control-input:checked {
           & ~ .form__control-label {
                border: 1px solid $brand-1;
                background-color: $brand-1-extra-light;
            }
        }
    }
}
.disabled .checkbox--alt {
    pointer-events: none;
    .form__control-label {
        &:before,
        &:after {
            opacity: .5;
        }
        background-color: rgba(250, 250, 250, .5);
        .p {
            opacity: .5;
        }
    }
}
.checkbox-extra__alert {
    color: $error-colour;
    font-size: $f-xs;
    padding-top: 1rem;
}

// Radio button modals
.radio-button-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: -.6rem;
    margin-right: -.6rem;
    .form-label-step {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: .6rem;
    }
    .radio--button {
        flex: 0 0 9.090909090909091%;
        max-width: 9.090909090909091%;
        padding-left: .6rem;
        padding-right: .6rem;
        .button {
            &:not(.button--cta) {
                font-size: 1.5rem;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        .form__control-input:checked {
            & ~ {
                .button {
                    padding-left: 1.2rem;
                    padding-right: 3.7rem;
                    &:before,
                    &:after {
                        right: .8rem;
                    }
                }
            }
        }
    }
    &.layout-auto {
        justify-content: flex-start;
        .radio--button {
            flex: 0 0 auto;
            max-width: 100%;
            min-width: 6.8rem;
            .button {
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
    }
    &.layout-md-btn {
        justify-content: flex-start;
        .radio--button {
            flex: 0 0 auto;
            max-width: 100%;
            min-width: 6.8rem;
            .button {
                padding-left: 2rem;
                padding-right: 2rem;
                max-width: 11.2rem;
            }
        }
    }
    &--min-width {
        .radio--button {
            flex: 0 0 8.7rem;
            max-width: 8.7rem;
        }
    }
}

.radio-row {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    // padding-right: 8rem;
    @media (min-width: 860px) {
        padding-right: 8rem;
    }
    &__title {
        flex: 0 0 6.8rem;
        max-width: 6.8rem;
        padding-top: 1.4rem;
        font-size: $f-sm;
    }
}
.radio-list {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 calc(100% - 6.8rem);
    max-width: calc(100% - 6.8rem);
    .radio--button {
        flex: 0 0 8.5rem;
        max-width: 8.5rem;
    }
}

// Form steps label
.form-label-step {
    display: inline-flex;
    align-items: flex-start;
    color: $grey-4;
    font-family: $f2;
    font-weight: normal;
    margin-bottom: 2rem;
    font-size: $base-font-size;
    &__text {
        margin-top: 2px;
        .col-4 & {
            font-size: $f-sm;
        }
    }
    .is-active & {
        color: $brand-1;
        &__icon {
           color: $brand-1; 
           border: 1px solid $brand-1;
        }
    }
    .is-done & {
        color: $brand-1;
        &__icon {
           color: $brand-1; 
           border: 1px solid $brand-1-light;
           background-color: $brand-1-light;
        }
    }
    &__icon {
        margin-right: 1rem;
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border: 1px solid $grey-4;
        .i-check {
            margin-bottom: -1px;
        }
    }
}

.form__group--step {
    & + p {
        font-size: $f-xs;
    }
    &.is-disabled {
        pointer-events: none;
        .form__control-label {
            color: $grey-4;
            background-color: rgba(0, 0, 0, 0);
        }
    }

    // For board form we don't want to show disabled options for chassis, probably this can be moved in a better section
    &.is-active.board-chassis-options {
        .is-disabled {
            display: none;
        }
    }
    &.layout-50 {
        .form-label-step {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .radio--button {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    &.layout-33 {
        .form-label-step {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .radio--button {
            text-align: center;
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
    }
}

.form__group-help-text {
    padding-top: 1rem;
    color: $grey-6;
    font-size: $f-xs;
}

.form-show {
    display: block;
}

.form-hide {
    display: none;
}

.form__upload {
    max-width: 232px;
    position: relative;
    &-input {
        width: 100%;
        appearance: none;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
    }
    &-ui {
        display: flex;
        align-items: center;
        padding: 2rem 1.6rem;
        background: rgba(66, 180, 230, 0.2);
        border: 1px dashed $brand-2;
        border-radius: 3px;
        font-family: $f3;
        font-size: $f-xs;
        font-weight: 700;
        color: $brand-2;
        .i-upload {
            font-size: 1.6rem;
            margin-right: 2.8rem;
        }
    }
    &.is-loading {
        pointer-events: none;
        opacity: 0.4;
    }
}

.re-captcha {
    display: flex;
    justify-content: end;
}

// Globaly remove spinners on number inputs
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield;
}